import {
  OverdraftAccountAvailableForUserFn,
  OverdraftAccountAvailableListValue
} from "./overdraft-account-available-list-component.domain";
import {CustomerAccessLevelValue, NotNullable} from "../types.domain";
import {OverdraftCustomerPhysicalCardSelectValue} from "./overdraft-customer-physical-card-select-component.domain";
import {CurrencyInputValue} from "./currency-input-component.domain";
import { CustomerAccessLevelAndRoleAccessValue } from "./customer-access-level-and-role-access-component.domain";


export interface AllocateUserToPismoAccountDialogData {
  overdraftAccountAvailableForUserFn: OverdraftAccountAvailableForUserFn;
  userId: number;
}

export type AllocateUserToPismoAccountDialogResult = {
  type: 'close',
} | {
  type: 'submit',
  userId: number,
  account: NotNullable<OverdraftAccountAvailableListValue>,
  card: NotNullable<OverdraftCustomerPhysicalCardSelectValue>,
  monthlyLimit: NotNullable<CurrencyInputValue>,
  customerAccessLevel: NotNullable<CustomerAccessLevelAndRoleAccessValue>,

}



