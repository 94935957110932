export type Address2ComponentValue = {
  address: string,
  StreetNumber: string,
  StreetName: string,
  StreetType: string,
  Suburb: string,
  State: string,
  UnitNumber?: string,
  Postcode: string,
} | null;
