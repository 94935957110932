import {Observable} from "rxjs";

export type OverdraftAccountAvailableListValue = OverdraftAccountAvailable | null;

export interface OverdraftAccountAvailable {
  customerId: number,
  entityName: string,
  pismoAccountNumber: number,
}

export type OverdraftAccountAvailableForUserFn = (userId: number) => Observable<OverdraftAccountAvailable[]>;
