import {MinMaxRateValue} from './min-max-rate-component.domain';

export type InsurancePremiumRateCardValue = InsurancePremiumRateCard | null;
export interface InsurancePremiumRateCard {
  baseInterestRate: number,
  loanAmount: MinMaxRateValue,
  loanTerms: MinMaxRateValue,
  adverseOnFile: number,
  lowEquifaxScore: number,
  docFee: MinMaxRateValue,
}
