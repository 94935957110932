import {AssetCategoryRateValue,} from './asset-category-rate-component';
import {  AssetTypeRateValue, } from './asset-type-rate-component.domain';
import {  MinMaxRateValue} from './min-max-rate-component.domain';

export type NonAssetRateCardValue = NonAssetRateCard | null;
export interface NonAssetRateCard {
  baseInterestRate: number,
  loanAmount: MinMaxRateValue,
  eotAge: MinMaxRateValue,
  loanTerms: MinMaxRateValue,
  brokerage: MinMaxRateValue,
  adverseOnFile: number,
  privateSale: number,
  privateSaleDocFee: number,
  noPropertyOwnership: number,
  carsPropertyOwner: number,
  lowEquifaxScore: number,
  assetConditionUsed: number,
  docFee: MinMaxRateValue,
  assetCategory: AssetCategoryRateValue,
  assetType: AssetTypeRateValue,
}

