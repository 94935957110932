import {Observable} from 'rxjs';
import {NotNullable} from '../types.domain';

// export type AggregatorAndOriginatorListValue = AggregatorAndOriginatorValue | null;
//
// export interface AggregatorAndOriginatorValue {
//   OriginatorBusinessId: number,
//   EntityName: string,
//   SalesforceId: string,
// }

export type CustomerListValue =  {
  customerId: number,
  EntityName: string,
  SalesforceId: string,
} | null


export type AggregatorAndOriginatorListValue = (AggregatorValue | OriginatorValue) |  null;

export type AggregatorValue = {
  kind: 'Aggregator',
  OriginatorBusinessId: number,
  EntityName: string,
  SalesforceId: string
}

export type OriginatorValue = {
  kind: 'Originator',
  OriginatorBusinessId: number,
  EntityName: string,
  SalesforceId: string,
}


export type CustomerListValueGetFn = () => Observable<NotNullable<CustomerListValue>[]>;
export type AggregatorAndOriginatorListValueGetFn = () => Observable<NotNullable<AggregatorAndOriginatorListValue>[]>;

