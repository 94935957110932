import {TotalPaymentBreakupDialogData} from "./total-payment-breakup-dialog.domain";
import {FormControl} from "@angular/forms";

export interface ConsumerPricingComparisonDialogData {
  currentValue: TotalPaymentBreakupDialogData,
  newValue: TotalPaymentBreakupDialogData,
}

export interface ConsumerPricingComparisonDialogResult {
  acceptedQuotation: boolean;
  docFee: number,
}

export type ConsumerPricingComparisonDialogDatasource = ({
  name: string,
  currentValue: string | null | undefined,
  newValue: string | null | undefined,
  formControl?: FormControl<string | null>, // if exists, field is editable
  type: 'text',
  currentValueClick?: (currentValue: string | null | undefined)=>void,
  newValueClick?: (newValue: string | null | undefined)=>void,
} | {
  name: string,
  currentValue: number | string | null | undefined,
  newValue: number | string | null | undefined,
  formControl?: FormControl<string | number | null>, // if exists, field is editable
  type: 'percentage',
  currentValueClick?: (currentValue: string | number | null | undefined)=>void,
  newValueClick?: (newValue: string | number | null | undefined)=>void,
} | {
  name: string,
  currentValue: number | string | null | undefined,
  newValue: number | string | null | undefined,
  formControl?: FormControl<string | number | null>, // if exists, field is editable
  type: 'currency',
  currentValueClick?: (currentValue: string | number | null | undefined)=>void,
  newValueClick?: (newValue: string | number | null | undefined)=>void,
})[]

