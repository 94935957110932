import {DocumentTag, UploadAzureFiles} from "../types.domain";

export interface SelectableTagsDialogData {
  selectableTags: DocumentTag[],
  tagsSel: DocumentTag[],
  allTags: DocumentTag[],
  showMaxTagErrorMessage: boolean,
}

export interface SelectableTagsDialogResult {
  valid: boolean;
  files: UploadAzureFiles;
  selectedTags: DocumentTag[];
}


