import {EntityTypeValue, } from './entity-type-component.domain';
import { SearchCompanyByABNResult} from '../../domain/application-service.domain';

export type BusinessSearchValue = BusinessSearchResultValue | BusinessSearchFreeTextValue | null;

export type BusinessSearchResultValue = {
  type: 'search-result',
  organisationName: string;
  abn: string;
  acn: string;
  status: string;
  stateCode: string;
  postcode: string;
  result?: SearchCompanyByABNResult,
  entityTypeValue: EntityTypeValue,
};

export type BusinessSearchFreeTextValue = {
  type: 'free-text',
  organisationName: string;
  abn: string;
  acn: string;
};
