export interface HubSpotTicket {
    id: string;
    stage: string | null;
    ownerId: string | null;
    currentPriority: string | null;
}

export interface SalesforceContract {
    Id: string;
    AccountId: string;
    Account: {
        name: string;
        phone: string;
        ec1_Email__c: string;
        ec1_MobileNumber__c: string;
        ec1_ABN__c: string;
        ec1_ACN__c: string;
        PrimaryContact__r: {
            Email: string;
            MobilePhone: string;
            Firstname: string;
            Lastname: string;
        }
    };
    ec1_Introducer__c: string;
    ec1_Introducer__r: {
        ec1_RelationshipType__c: string;
        ec1_ABN__c: string;
        ec1_ACN__c: string;
        Name: string;
    };
    Product__c: string;
    Max_Director_Equifax__c: number;
    Property_Ownership__c: boolean;
    ABN_Number__c: string;
    ACN_Number__c: string;
    Name: string;
    Goods_Description__c: string;
    Number_of_Installments__c: number;
}