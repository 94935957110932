export interface ApplicationAuditLog {
  id: number,
  user: {
    userId: number,
    name: string,
    email: string,
  } | null,
  applicationId: number,
  field: string,
  oldValue: string,
  newValue: string,
  dateGenerated: string,
  assetId: number,
}

export interface CreateApplicationAuditLog {
    UserId: number,
    ApplicationId: number,
    Field: string,
    OldValue: string,
    NewValue: string,
    AssetNumber: number
    AssetId: number,
}