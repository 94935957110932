export type statusBox = 'draft' |
  'under-review' |
  'waiting-for-bank-statments' |
  'submitted-to-credit' |
  'more-info' |
  'not-verified' |
  'paid' |
  'credit-approved' |
  'payment-pending' |
  'success';

