import {AmortisationScheduleEntry} from './armotisation-schedule-component.domain';
import {AmortizationChartData} from './armotization-chart-component.domain';
import {PaymentChartData} from './payment-chart-component.domain';
import {RepaymentEstimationEntry} from './repayment-estimation-component.domain';
import {Moment} from 'moment';

export interface TotalPaymentBreakupDialogData {
  amortizationScheduleData: AmortisationScheduleEntry[];
  amortizationChartData: AmortizationChartData;
  paymentChartData: PaymentChartData;
  repaymentEstimationData: RepaymentEstimationEntry[];
  calculationLog?: {log: string, rate?: number}[],
  // isBusinessLoan?: boolean;
  // hideBrokerage?: boolean,
  // onlyShowPaymentChartData?: boolean;
}


export interface TotalPaymentBreakupDialogData2 {
  loadFn: (date: Moment) => TotalPaymentBreakupDialogData;
  isQuote: boolean;
  paymentChartHideBrokerOriginationFee: boolean, // business loan no broker origination fee
  paymentChartHideBalloonPayment: boolean,       // business loan no balloon payment
  paymentChartHideBrokerage: boolean,            // direct-sales no brokerage
  showPaymentChart: boolean,                     // available for all applications
  showArmotizationChart: boolean,                // not available for direct-sales
  showRepaymentEstimation: boolean,              // not available for direct-sales
  showArmotisationSchedule: boolean,             // not available for direct-sales
}

export interface TotalPaymentBreakupDialogResult { acceptedQuotation: boolean };
