import {AzureStorageDocument} from '../types.domain';

export interface ConsolidatedDocument {
  name: string,
  salesforceFileId?: string,
  type: string,
  status: 'SUCCESS' | 'ERROR' | '';
}

export type DocumentStatus = 'Pending Approval' | 'Approved' | 'Declined' | 'Optional' | '' | any;
export type DocumentStageRequired = string;

export interface GroupedDocument {
  groupName: string;
  description: string;
  value: string;
  status: DocumentStatus;
  docs: AzureStorageDocument[];
  required: boolean;
  nonStandardCondition?: boolean;
  nonStandardConditionStatus?: "Pending" | "Accepted" | "Rejected" | "In Progress";
  standardCondition?: boolean;
  standardConditionStatus?: "Pending" | "Accepted" | "Rejected" | "In Progress";
  disableDocumentUpload?: boolean;
}

export type GroupDocumentDetails = GroupedDocument[];

export interface NotificationGroupedDocuments extends GroupedDocument {
  wholeGroup: boolean;
}

export interface DocumentNotificationData {
  groupName: string;
  space: number;
  docName: string;
}

export interface DocumentWorklist {
  documentWorklistId: number;
  applicationId: number;
  brokerAppId: string;
  createdTime: Date;
  name: string;             // client name
  brokerName?: string; // broker name
  stage: string;            // SF stage
  assignedTo: number | null;// user id
  userName: string;         // user full name
  status: 'complete' | 'pending';
}

export type DocumentWorklists = DocumentWorklist[];

export interface DocumentWorklistTableData {
  documentWorklistId?: number;
  applicationId: number;
  createdTime: Date;
  userId: number | null;
  status: 'complete' | 'pending';
  entityName: string;
  brokerAppId: string;

  // status?: if the internal user processes that request or not
  // lastUpdatedTime?: people can click 'submit for review' many times for the same application
  // /// we should only display one record on the screen. Do we need to keep the first click time and the latest click time?
}

export type DocumentWorklistCategory = "all" | "credit" | "client-services" | "settlements";
