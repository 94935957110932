export type OfacAddUserAndCustomerAddressFoundEntry = OfacAddUserAddressFoundEntry | OfacAddCustomerAddressFoundEntry;

export type OfacAddUserAddressFoundEntry = {
  UserId: number,
  Name: string,
  Address: string,
  Type: 'UserPostalAddress' | 'UserResidentialAddress'
}

export type OfacAddCustomerAddressFoundEntry = {
  CustomerId: number,
  Name: string,
  Address: string,
  Type: 'CustomerAddress'
}

export interface OfacSdnCustomerNameFoundEntry {
  CustomerId: number,
  EntityName: string,
}

export interface OfacSdnUserNameFoundEntry {
  UserId: number,
  Name: string,
  MatchName: string,
}

export interface OfacSdn {
  ent_num: number,
  SDN_Name: string,
  SDN_Type: string,
  Program: string,
  Title: string,
  Call_Sign: string,
  Vess_type: string,
  Tonnage: string,
  GRT: string,
  Vess_flag: string,
  Vess_owner: string,
  remarks: string,
}

export interface OfacAdd {
  Ent_num: number,
  Add_num: number,
  Address: string,
  CityStateProvincePostCode: string,
  Country: string,
  Add_remarks: string,
}
