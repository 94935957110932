

export interface ApplicantsPrivacyConsentDialogData {
  atLeastOneWithPrivacyConsent: boolean;
  applicants: {
    firstName: string,
    lastName: string,
    privacyConsentObtained: boolean,
  }[]
}

export type ApplicantsPrivacyConsentDialogResult = ApplicantsPrivacyConsentDialogSaveResult | ApplicantsPrivacyConsentDialogCancelResult;

export interface ApplicantsPrivacyConsentDialogCancelResult {
  type: 'cancel',
}
export interface ApplicantsPrivacyConsentDialogSaveResult {
  type: 'save',
  applicants: {
    firstName: string,
    lastName: string,
    privacyConsentObtained: boolean,
  }[];
}

