import {  EntityTypeValue, } from './entity-type-component.domain';
import {  GenderValue, } from './gender-component.domain';
import {    PersonalAssetsValue, } from './personal-assets-component.domain';
import {    PersonalLiabilitiesValue, } from './personal-liabilities-component.domain';
import {  PropertyOwnerWithAddressValue, } from './property-owner-with-address-component.domain';
import {  TitleSelectionValue, } from './title-selection-component.domain';
import {  YesNoValue, } from './yes-no-component.domain';
import { Address2ComponentValue } from './address2-component.domain';
import {Moment} from 'moment';
import {BusinessNumberSearchValue} from './business-number-search-component.domain';

export type EntityPartner = {
  kind: 'Partner',
  type: 'Entity',
  organisation: Exclude<BusinessNumberSearchValue, null>,
  organisationType: Exclude<EntityTypeValue, null>,
  acn: string,
  abn: string,
  manualEntry: boolean,
};
export type IndividualPartner = {
  kind: 'Partner',
  type: 'Individual',
  title: TitleSelectionValue,
  firstName: string,
  lastName: string,
  middleName: string,
  gender: GenderValue,
  dob: Moment,
  guarantor: YesNoValue,
  residentialAddress: Address2ComponentValue,
  privacyConsentObtained: Exclude<YesNoValue, null>,
  propertyOwner: PropertyOwnerWithAddressValue,
  email: string,
  mobileNumber: string,
  personalAssets: PersonalAssetsValue,
  personalLiabilities: PersonalLiabilitiesValue,
};
export type PartnerValue = (IndividualPartner | EntityPartner)[] | null;
