import {Moment} from 'moment';
import {BusinessSearchValue} from './business-search-component.domain';
import {AggregatorSearchComponentValue} from './aggregator-search-component.domain';
import {AssetSelectionComponentValue} from './asset-selection-component.domain';
import {AssetConditionValue} from './asset-condition-component.domain';
import {YesNoValue} from './yes-no-component.domain';
import {LoanTermValue} from './loan-terms-selection-component.domain';
import {PaymentFrequencyValue} from './payment-frequency-selection-component.domain';
import {FinanceTypeValue} from './finance-type-component.domain';
import {BalloonPaymentValue} from './balloon-payment-component.domain';
import {BrokerageSelectionValue} from './brokerage-selection-component.domain';
import {EntityTypeValue} from './entity-type-component.domain';
import {PrimaryIndustrySelectionValue} from './primary-industry-selection-component.domain';
import {SecondaryIndustrySelectionValue} from './secondary-industry-selection-component.domain';
import {Address2ComponentValue} from './address2-component.domain';

import {GuarantorValue} from './guarantor-component.domain';
import {ContactValue} from './select-contact-component.domain';
import {ReferenceValue} from './reference-component.domain';
import { UserSelectionValue } from './user-selection-component.domain';
import {ApplicationApplicant} from '../../domain/types.domain';
import {TransactionValue} from '../component/transaction-type-component.domain';
import { SalesforceContactSameAccountSelectionValue } from './salesforce-contact-same-account-selection-component.domain';
import { NumberInputValue } from './number-input-component.domain';
import {LoanTermsSelectionWithInputValue} from "./loan-terms-selection-with-input-component.domain";

export type ApplicationSelectionType = 'app' | 'quote' | 'bank-statement' | 'asset' | 'credit' | 'documents' | 'kyc-aml' | 'add-asset' | 'lvr-calculator' | 'pricing';

// export type DrawdownSelectionType = 'trade' | 'floorplan';

export interface AssetFinanceSummary {

  repayment: string;
  estimatedDrawDownDate: Moment;
  brokerApplicationId: string;

  organisation: BusinessSearchValue;
  addtionalBrokerCorrespondent: UserSelectionValue;
  broker: AggregatorSearchComponentValue;

  invoiceAmount: number;
  depositAmount: number;
  loanAmount: number;

  asset: AssetSelectionComponentValue;
  assetCondition: AssetConditionValue,
  transactionType: TransactionValue,
  loanTerms: LoanTermsSelectionWithInputValue,
  paymentFrequency: PaymentFrequencyValue,
  financeType: FinanceTypeValue;
  docFee: YesNoValue,
  balloonPayment: BalloonPaymentValue,
  balloonPaymentValue: number,
  brokerOriginationFee: number;
  brokerage: BrokerageSelectionValue,
  propertyOwner: YesNoValue,
  adverseOnFile: YesNoValue,
  equifaxScoreAboveThreshold: YesNoValue,

  organisationType: EntityTypeValue,
  abn: string;
  acn?: string;
  annualRevenue: number;
  operatesInCommercialPremises: YesNoValue,
  primaryIndustry: PrimaryIndustrySelectionValue,
  industrySector: SecondaryIndustrySelectionValue,
  address: Address2ComponentValue,
  businessLandline: string,

  applicants: Exclude<ApplicationApplicant, null>,

  guarantors: GuarantorValue;

  primaryContact: ContactValue;

  brokerFlowId?: string;

  references: ReferenceValue;

  applicationNotes: string;
  brokerContact:SalesforceContactSameAccountSelectionValue;
}
