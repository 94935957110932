export interface PeriodicElement {
  content: string;
  icon?: string;
  symbol: string;
}

export interface infoNeeded {
  content1: string;
  icon1?: string;
  symbol1: string;
}
