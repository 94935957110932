import { Observable } from "rxjs";
import { IndividualWithResult } from "./digital-id-service.domain";
import { ApiResponse, AzureStorageContainer, AzureStorageDocument, Base64File } from "./types.domain";

export interface CompareFacesSourceImage {
    type: "Buffer",
    data: Uint8Array;
}

export interface CompareFacesBody {
    sourceImage: CompareFacesSourceImage,
    targetFile: string,
    targetFileType: string,
    individualId: string,
    applicationId: number,
    livenessConfidence: number,
    passLivenessTest: boolean,
}

export interface BoundingBoxData {
    BoundingBox: {
        Height: number,
        Left: number,
        Top: number,
        Width: number,
    },
    Confidence: number,
}

export interface FaceMatchData {
    Face: BoundingBoxData,
    Similarity: number,
}

export interface CompareFacesResult {
    $metadata: {
        httpStatusCode: number,
        requestId: string,
        extendedRequestId: string | undefined,
        cfId: string | undefined,
        attempts: number,
        totalRetryDelay: number
    },
    FaceMatches: FaceMatchData[],
    SourceImageFace: BoundingBoxData,
    UnmatchedFace: FaceMatchData[],
}

export interface CompareFacesError {
    Error: boolean,
    Message: string,
    Similarity: number
}

export interface RekognitionTokenValidationResult {
    individual: IndividualWithResult,
    doc: AzureStorageDocument | null,
    applicationId: number,
    verificationResult: IdentityVerificationResult | null,
}

export interface UploadDriverLicenceBody {
    file: Base64File;
    individualId: string;
    applicationId: number;
  }

export interface IdentityVerificationResult {
    id: number,
    individualId: string,
    livenessConfidence: number,
    livenessPicture: string,
    passLivenessTest: boolean,
    faceCompareScore: number,
    passFaceCompare: boolean,
    livenessPictureBoundingBox: BoundingBoxData,
    driverLicenceBoundingBox: FaceMatchData[],
    driverLicenceFile: string,
    identityVerificationTime: string,
    faceCompareCounter: number,
    lastUpdatedBy: number | null,
    lastUpdatedTime: string,
    lastUpdatedGivenName: string | null,
    lastUpdatedFamilyName: string | null,
}

export interface IdentityVerificationLinkDialogData {
    applicationId: number,
    individualId: string,
    idVerifyUrl: string,
    email: string,
    name: string,
    sendIdVerifyLinkFn: SendIdVerifyLinkFn,
}

export interface IdentityVerificationLinkDialogResult {
    
}

export type DownloadDocumentFromAzureFn = (file: string, container?: AzureStorageContainer) => Observable<{type: "Buffer"; data: Buffer}>;

export interface SendIdVerifyLinkBody {
    link: string;
    email: string;
    name: string;
}

export type SendIdVerifyLinkFn = (email: string, name: string, link: string) => Observable<ApiResponse>;
export type BypassFaceCompareFn = (identityVerificationId: number) => Observable<ApiResponse>;
export type DeleteIdentityVerificationFn = (identityVerificationId: number) => Observable<ApiResponse>;