import {EnableDisable2faFn, Get2faTotpVerificationCodeUriFn, Verify2faTokenFn} from '../2fa-service.domain';

export interface Enable2FAPasswordDialogData {
}

export type Enable2FAPasswordDialogResult = {
  email: string
  password: string,
}

export interface Enable2FADialogData {
    get2FaVerificationCodeFn: Get2faTotpVerificationCodeUriFn;
    enableDisable2faFn: EnableDisable2faFn;
    verify2faGeneratedTokenCodeFn: Verify2faTokenFn;
}

export interface Enable2FADialogResult {
}


export interface TFAActivatedDialogData {
    enableDisable2faFn: EnableDisable2faFn;
}

export interface TFAActivatedDialogResult {
}



  // export interface enableDisable2fa {
  //   is2FA : boolean;
  // }

  export interface registeredIpToken {
    UserId : number;
    ipToken : string;
  }
