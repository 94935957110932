export interface OOBPreferenceRequest {
  clientHashId: string,
  acsTransactionId: string,
  cardHashId: string,
  email: string,
  phoneNumber: string
}

export type OOBPreferenceResponse = {
  respCode: "00",
  respDescription: "OOB Only"
} | {
  respCode: "01",
  respDescription: "OOB with fallback to OTP"
} | {
  respCode: "02",
  respDescription: "OTP only"
}

export type OOBInitiateRequest = {
  acsTransactionId: string,
  clientHashId: string,
  cardHashId: number,
  merchantName: string,
  maskedCardNumber: string,
  transactionAmount: string,
  transactionCurrency: string,
  purchaseDateTime: string
}

export type OOBCallback = {
  acsTransactionId: string,
  referenceNumber: string,
  status: "Success",
  statusCode: "000"
}