import { BusinessSearch2Value, } from '../component/business-search2-component.domain';
import {  EntityTypeValue, } from '../component/entity-type-component.domain';
import {  GenderValue, } from '../component/gender-component.domain';
import { PersonalAssetsValue, } from '../component/personal-assets-component.domain';
import { PersonalLiabilitiesValue, } from '../component/personal-liabilities-component.domain';
import {  PropertyOwnerWithAddressValue, } from './property-owner-with-address-component.domain';
import {  TitleSelectionValue, } from './title-selection-component.domain';
import {  YesNoValue, } from './yes-no-component.domain';
import {Address2ComponentValue} from '../component/address2-component.domain';
import {BusinessNumberSearchValue} from '../component/business-number-search-component.domain';

import {Moment} from 'moment';

export type EntityMember = {
  kind: 'Member',
  type: 'Entity',
  organisation: Exclude<BusinessNumberSearchValue, null>,
  organisationType: Exclude<EntityTypeValue, null>,
  acn: string,
  abn: string,
  manualEntry: boolean,
};
export type IndividualMember = {
  kind: 'Member',
  type: 'Individual',
  title: TitleSelectionValue,
  firstName: string,
  lastName: string,
  middleName: string,
  gender: GenderValue,
  guarantor: YesNoValue,
  dob: Moment,
  residentialAddress: Address2ComponentValue,
  privacyConsentObtained: Exclude<YesNoValue, null>,
  propertyOwner: PropertyOwnerWithAddressValue,
  email: string,
  mobileNumber: string,
  personalAssets: PersonalAssetsValue,
  personalLiabilities: PersonalLiabilitiesValue,
};
export type MemberValue = (IndividualMember | EntityMember)[] | null;
