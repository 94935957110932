
export interface GooglePlaceTextSearchResponse {
  candidates: {
    formatted_address?: string,
    name?: string,
  }[],
  status: 'OK' | 'ZERO_RESULTS' | 'INVALID_REQUEST' | 'OVER_QUERY_LIMIT' | 'REQUEST_DENIED' | 'UNKNOWN_ERROR',
  error_message?: string,
  info_messages?: string[],
}


