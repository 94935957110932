import {MobilePlatformType} from "./types.domain";

export interface ExpoPushTokenData {
  id: number;
  userId: number;
  token: string;
  description: string;
  isDeleted: boolean;
  lastActiveSession: string;
  appId: string;
  platform: MobilePlatformType;
}

export interface EnableOrDisableNotificationBody {
  enable: boolean;
  token: string;
  userId: number;
  description: string;
  appId: string;
  appVersion: string;
  appPlatform: MobilePlatformType;
}
