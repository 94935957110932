import { Observable } from "rxjs";
import {NotNullable} from '../types.domain';

export type UserSameCompanySelectionValue = {
  UserId: number,
  Name: string,
  Email: string,
  sfContactId: string,
} | null;


export type GetUserSameCompanyFunc = (salesforceId: string) => Observable<NotNullable<UserSameCompanySelectionValue>[]>;
