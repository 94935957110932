export type BusinessNumberSearchResult = {
  'match-name': string,
  'organisation-number': number,
  'organisation-type': string, // eg. P/L
  'organisation-number-heading': string, // eg. ACN
  'state': string,
  'asic-report'?: {
    type: 'result',
    'asic-name': string,
    'asic-organisation-number': number,
    'asic-a-b-n': number,
    'asic-organisation-number-heading': string, // eg. ACN
    'asic-type': string // eg. APTY
    'asic-status': string // eg. REG
    'asic-jurisdiction': string // eg. ASIC
    'asic-locality': string,
    'asic-address-state': string,
    'asic-postcode': number,
  } | { type: 'error', ErrorMsg: string},
  'australian-business-register-report': {
    type: 'result',
    ASICNumber: number,
    ABN: number,
    ABNStatus: string,
    ABNStatusFromDate: string, // YYYY-MM-DD
    EntityTypeInd: string, // eg. PRV
    EntityTypeText: string,  // eg. Australian Private Company
    'Non-IndividualName': string,
    'IndividualName': string,
    BusinessAddressState: string,
    BusinessAddressPostcode: string,
    GSTStatus: string,
    GSTStatusFromDate: string, // YYYY-MM-DD
    TradingName: string,
    OtherEntity: {
      OtherEntityName: string,
    }
  } | {type: 'error', ErrorMsg: string},
  'bureau-report': {
    type: 'result',
    address: {
      'street-number': number,
      'street-name': string,
      'suburb': string,
      'state': string,
      'postcode': number,
    },
    'last-search-date': string, // YYYY-MM-DD
    'bureau-reference': number,
    'bca-company-type': string,
    'bca-name': string,
  } | {type: 'error', ErrorMsg: string},
}[];

export type BusinessNumberSearchValue = {
  acn: string,
  abn?: string,
  name: string,
  displayName: string,
  result?: BusinessNumberSearchResult[number],
} | null;
