import {
  Directive, DoCheck, EmbeddedViewRef, inject, Input, OnChanges, SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from "@angular/core";
import {UserandPriviledges} from "@portal-workspace/grow-shared-library";
import {getUser} from "../components/component-utils";
import _ from 'lodash';

class SecurityDirectiveContext {
}

@Directive({
  standalone: true,
  selector: '[security]',
})
export class SecurityDirective implements OnChanges, DoCheck {

  viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
  templateRef: TemplateRef<any> = inject(TemplateRef);

  @Input({required: false}) securityUser!: Pick<UserandPriviledges, 'priviledges'>;
  @Input({required: true, alias: 'security'}) securityPrivs: string[] = [];
  @Input({required: false}) securityCache = false;

  createdViewRef: EmbeddedViewRef<any> | null = null;
  context: SecurityDirectiveContext = new SecurityDirectiveContext();

  ngOnChanges(changes: SimpleChanges): void {
    const changeUser = changes['securityUser'];
    const changePrivs = changes['security'];
    if (changeUser || changePrivs) {
      this.eval();
    }
  }

  ngDoCheck(): void {
    this.eval();
  }

  eval() {
    const user = this.securityUser ?? getUser();
    const privs = this.securityPrivs ?? [];
    if (_.intersection(user.priviledges ?? [], privs ?? []).length) {
      this.viewContainerRef.detach();
      if (!this.createdViewRef || (this.createdViewRef && !this.securityCache)) {
        this.createdViewRef = this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
      }
      this.viewContainerRef.insert(this.createdViewRef);
    } else {
      this.viewContainerRef.detach()
    }
  }

}
