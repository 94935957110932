export type ProfileComponentEvent = ProfileComponentSaveProfileEvent | ProfileComponentSavePasswordEvent | ProfileComponentCancelEvent | ProfileComponentLogoutEvent ;

export interface ProfileComponentSaveProfileEvent {
  type: 'save-profile',
  userId: number,
  FirstName: string,
  MiddleName: string,
  LastName: string,
  Email: string,
  MobileNumber: string,
}

export interface ProfileComponentSavePasswordEvent {
  type: 'save-password',
  userId: number,
  password: string,
}

export interface ProfileComponentCancelEvent{
  type: 'cancel'
}

export interface ProfileComponentLogoutEvent {
  type: 'logout';
}
