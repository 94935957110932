export interface AmortisationScheduleEntry {
  year: string,
  payment: string,
  principal: string,
  interest: string,
  balance: string,
  details: {
    monthYear: string,
    payment: string,
    principal: string,
    interest: string,
    balance: string,
  }[]
};

