import {Observable} from 'rxjs';
import {User, UserandPriviledges} from '../types.domain';
import {MatchConfirmPasswordFn} from '../2fa-service.domain';

export type UpdateUserPasswordFn = (userId: number, data: { password: string}) => Observable<UserandPriviledges | null>;

export interface ConfirmPasswordDialogData {
  userId: number;
  // updateUserPasswordFn: UpdateUserPasswordFn;
  matchConfirmPasswordFn: MatchConfirmPasswordFn;
}

export interface ConfirmPasswordDialogResult {
  valid: boolean,
  newPassword: string | null; // only available when valid is true
}
