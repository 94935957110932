import {DocumentTag, Metadata, UploadAzureFiles} from '../types.domain';
import {GroupedDocument} from './document-details-component.domain';
import {Application} from '../application-service.domain';
import { GetUserFn } from '../auth-service.domain';

export interface UploadFileDialogData {
  title: string;
  tags?: string[];
  metadata?: Metadata;
  allTags?: DocumentTag[];
  enableCustomTag?: boolean;
}

export interface UploadFileDialogResult {
  valid: boolean;
  files: File[];
}

export interface UploadFilesWithTagsDialogResult {
  valid: boolean;
  files: UploadAzureFiles;
  selectedTags: DocumentTag[];
}

export interface FileWithTags {
    fileName: string | null;
    fileTags: DocumentTag[];
  }

  
export interface UploadFilesMultiTagsDialogResult {
  valid: boolean;
  files: UploadAzureFiles;
  selectedTags: DocumentTag[];
}

export interface ModifyFileTagsDialogData {
  fileName: string;
  selectedTags: string[];
  allTags: DocumentTag[];
}

export interface ModifyFileTagsDialogResult {
  tags: string[];
  readyForSubmission: boolean;
}

export interface RequiredDocumentListDialogData {
  requiredTags: DocumentTag[]; // all the required tags
  dbTags: DocumentTag[]; // all the tags in db column
  allTags: DocumentTag[]; // all tags including const tags
}

export interface RequiredDocumentListDialogResult {
  tags: DocumentTag[]; // new db tags
  readyForSubmission: boolean;
}

export interface CustomDocumentTagDialogData {
  nonStandardCondition: boolean;
}

export interface CustomDocumentTagDialogResult {
  tag: DocumentTag;
  readyForSubmission: boolean;
}

export interface DocumentNotificationDialogData {
  docs: GroupedDocument[];
  application: Application;
  getUserFn: GetUserFn;
}

export interface DocumentNotificationDialogResult {
  readyForSubmission: boolean;
  additionalInformation: string;
  removeFromList: boolean;
  emailList: string[];
}

export interface DocumentReviewDialogData {
  title: string;
  groupNames:GroupedDocument[]
}

export interface DocumentReviewDialogResult {
  valid: boolean;
  groupNames:GroupedDocument[];
  documentNotes: string;
}