

export interface InstitutionStatus {
  name: string,
  type: string,
  connectionStatus: 'Deprecated' | 'Degraded' | 'Healthy' | 'Unknown',
  region: string,
  isBusinessBank: boolean,
  isEStatementAvailable: boolean,
  isTransactionListingAvailable: boolean,
}

export interface BankfeedsInstitutionsQueryResponse {
  institutions: {
    slug: string,
    name: string,
    credentials: {
      name: string,
      fieldID: string,
      type: string,
      description: string,
      values: string,
    }[],
    status: string,
    severity: string,
    present: string,
    present_service: string,
    available: string,
    searchable: string,
    display: string,
    searchVal: string,
    region: string,
    export_with_password: string,
    estatements_supported: string,
    transaction_listings_supported: string,
    card_validation_supported: string,
    requires_preload: string,
    requires_mfa: string,
    is_business_bank: string,
    ocr_supported: string,
    type: string,
    do_not_proxy: string,
    updated_at: string,
    max_days: string,
    get_address_supported: string,
    supports_payment_summaries: string,
    is_supported: string,
    is_override_status_message: string,
    hide_merged_estatement_privacy_note: string,
    time_next_stats_cron: string,
    time_next_session_cron: string,
    time_success_rate_updated: string,
    current_success_rate_ignore_abandoned: string,
    current_success_rate_ignore_failed_login: string,
    current_success_rate_all: string,
    sessions_per_week: string,
    time_next_outages_cron: string,
  }[];
}
