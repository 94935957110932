export interface BankStatementData {
    accounts: BankStatementAccount[];
    transactionsByCategory: BankTransactionsByCategory[];
    revenue?: Revenue;
    Dishonours?: BankTransactionsWithSummary;
    LenderList?: BankTransactionsWithSummary;
    Overdrawn?: BankTransactionsWithSummary;
}

export interface BankStatementAccount {
    id: string;
    accountHolder: string;
    accountName: string;
    bsbAccNumber: string;
    bankName: string;
    isCreditProduct: boolean;
    firstDate: string;
    lastDate: string;
    balance: {
        current: number;
        available: number;
        closing: number;
        opening: number;
    };
    decisionMetrics: DecisionMetricsData[];
    dayEndBalances?: DayEndBalanceSummary[];
    monthlyBalance?: AccountMonthlyBalance;
    transactionSummary: {
        totalCredit: number;
        totalDebit: number;
        numCredit: number;
        numDebit: number;
    };
    transactionsByCategory: BankTransactionsByCategory[];
    minBalance?: number;
    avgBalance?: number;
    daysInNegative?: number;
}

export interface AccountMonthlyBalance {
    averagecredit: number;
    averagedebit: number;
    enddate: string;
    enddatecredit: string;
    enddatedebit: string;
    monthlycredit: { [key: string]: number };
    monthlydebit: { [key: string]: number };
    numcredit: number;
    numdebit: number;
    startdate: string;
    startdatecredit: string;
    startdatedebit: string;
    totalcredit: number;
    totaldebit: number;
}

export interface TimeFrameMetrics {
    totalCredit: number;
    totalDebit: number;
    monthlyAverageCredit: number;
    numberOfCredits: number;
    averageCreditAmount: number;
    medianCreditAmount: number;
    numberOfDebits: number;
}

export interface DecisionMetricsData {
    label: string;
    allTime: number | undefined;
    last180Days: number | undefined;
    last90Days: number | undefined;
}

export interface DayEndBalanceSummary {
    date: string;
    balance: string;
}

export interface BankStatementTransaction {
    id: string;
    accountId: string;
    date: string;
    amount: number;
    balance: number;
    description: string;
    type: 'credit' | 'debit';
    category: string;
    tags: TransactionTag[];
}


export interface Revenue {
    avgothercredit: number;
    avgotherincome: number;
    avgrevenue: number;
    avgwages: number;
    enddate: string;
    monthlyrevenue: number[];
    startdate: string;
    totalothercredit: number;
    totalotherincome: number;
    totalrevenue: number;
    totalwages: number;
}

export interface BankTransactionsByCategory {
    category: string;
    summary: BankTransactionsSummary;
    transactions: BankStatementTransaction[];
}

export interface BankTransactionsSummary {
    numcredit: number;
    numdebit: number;
    totalcredit: number;
    totaldebit: number;
}

export interface BankTransactionsWithSummary {
    summary: BankTransactionsSummary;
    transactions: BankStatementTransaction[];
}

// For frontend UI table
export interface BankStatementTransactionRow {
    id?: number;
    date: string,
    transaction: string,
    debit: number,
    credit: number,
    party: string,
    category: string,
    otherInfo: string,
    accountId: string,
}

interface TransactionTag {
    [keys: string]: string;
}