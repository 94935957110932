import { AssetCategoryRateValue } from './asset-category-rate-component';
import {  AssetTier1Value } from './asset-tier1-component.domain';
import {  AssetTier2Value } from './asset-tier2-component.domain';
import {  AssetTier3Value } from './asset-tier3-component.domain';
import { AssetTypeRateValue, } from './asset-type-rate-component.domain';
import {  MinMaxRateValue } from './min-max-rate-component.domain';
import {AssetTier4Value} from './asset-tier4-component.domain';
import {TransactionTypeRateValue} from './transaction-type-rate-component.domain';

export type AssetRateCardValue =  AssetRateCard | null;
export interface AssetRateCard {
  assetTier1: AssetTier1Value;
  assetTier2: AssetTier2Value;
  assetTier3: AssetTier3Value;
  assetTier4: AssetTier4Value;
  capRate: number;
  truck12T: number;
  noAssetBacked: number;
  transactionTypeRate: TransactionTypeRateValue,
  // privateSales: number;
  // privateSalesDocFee: number;
  adverseOnFile: number;
  lowEquifax: number;
  lowDepositUplift: number;
  gstAge: MinMaxRateValue;
  docFee: MinMaxRateValue;
  brokerage: MinMaxRateValue;
  assetCategory: AssetCategoryRateValue;
  assetType: AssetTypeRateValue;
  loanTerms: MinMaxRateValue;
  loanAmount: MinMaxRateValue;
}
