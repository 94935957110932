import {OverdraftPaymentBreakupDialogData} from "./overdraft-payment-breakup-dialog.domain";
import {FormControl} from "@angular/forms";


export interface BusinessOverdraftPricingComparisonDialogData {
  currentValue: OverdraftPaymentBreakupDialogData,
  newValue: OverdraftPaymentBreakupDialogData,
}

export interface BusinessOverdraftPricingComparisonDialogResult {
  acceptedQuotation: boolean;
  docFee: number,
}

export type BusinessOverdraftPricingComparisonDialogDatasource  = ({
  name: string,
  currentValue: string | null | undefined,
  newValue: string | null | undefined,
  formControl?: FormControl<string | null>, // if exists, field is editable
  type: 'text',
  currentValueClick?: (currentValue: string | null | undefined)=>void,
  newValueClick?: (newValue: string | null | undefined)=>void,
} | {
  name: string,
  currentValue: number | string | null | undefined,
  newValue: number | string | null | undefined,
  formControl?: FormControl<string | number | null>, // if exists, field is editable
  type: 'percentage',
  currentValueClick?: (currentValue: number | string | null | undefined)=>void,
  newValueClick?: (newValue: number | string | null | undefined)=>void,
} | {
  name: string,
  currentValue: number | string | null | undefined,
  newValue: number | string | null | undefined,
  formControl?: FormControl<string | number | null>, // if exists, field is editable
  type: 'currency',
  currentValueClick?: (currentValue: number | string | null | undefined)=>void,
  newValueClick?: (newValue: number | string | null | undefined)=>void,
})[]
