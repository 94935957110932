import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssetInspectionStatusChipComponent} from './asset-inspection-status-chip.component';


const modules = [
  CommonModule,
]

@NgModule({
    imports: [
        ...modules,
        AssetInspectionStatusChipComponent
    ],
    exports: [
        AssetInspectionStatusChipComponent,
        ...modules,
    ]
})
export class AssetInspectionStatusModule {

}
