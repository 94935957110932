export interface Configuration {
  MaintenanceMode: 'disable' | 'enable',  
  docusign_token: string,
  ApplePay: 'disable' | 'enable',
  minimumMobileSupportedVersion: string
}

export interface AppConfiguration {
  SettingName: string;
  SettingValue: string;
}
export type ConfigurationSettings = 'MaintenanceMode';
