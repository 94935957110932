export interface ExtendTermsDialogData {
  vehicleTitle: string;
  vehicleDescription: string;
  vehicleVIN: string;
  currentTerm: number;
  maxTerm: number;
}

export interface ExtendTermsDialogResult {
  requestedTerm: number;
}
