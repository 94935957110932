import { TitleSelectionValue } from '../component/title-selection-component.domain';
import {Address2ComponentValue} from '../component/address2-component.domain';
import {EntityTypeValue} from '../component/entity-type-component.domain';
import {BusinessNumberSearchValue} from '../component/business-number-search-component.domain';
import {GenderValue} from '../component/gender-component.domain';
import {PersonalAssetsValue} from '../component/personal-assets-component.domain';
import {PersonalLiabilitiesValue} from '../component/personal-liabilities-component.domain';
import {PropertyOwnerWithAddressValue} from '../component/property-owner-with-address-component.domain';
import {YesNoValue} from '../component/yes-no-component.domain';

import {Moment} from 'moment';

export type IndividualOrEntity = 'Individual' | 'Entity';
export type EntityGuarantor = {
  type: 'Entity',
  organisation: Exclude<BusinessNumberSearchValue, null>,
  organisationType: Exclude<EntityTypeValue, null>,
  abn: string,
  acn: string,
};
export type IndividualGuarantorWithoutAddress = {
  type: 'Individual',
  kind: 'WithoutPropertyAddress',
  title: TitleSelectionValue,
  firstName: string,
  lastName: string,
  middleName: string,
  gender: GenderValue,
  dob: Moment,
  residentialAddress: Address2ComponentValue,
  privacyConsentObtained: boolean,
  propertyOwner: YesNoValue,
  email: string,
  mobileNumber: string,
  personalAssets: PersonalAssetsValue,
  personalLiabilities: PersonalLiabilitiesValue,
};
export type IndividualGuarantorWithAddress = {
  type: 'Individual',
  kind: 'WithPropertyAddress',
  title: TitleSelectionValue,
  firstName: string,
  lastName: string,
  middleName: string,
  gender: GenderValue,
  dob: Moment,
  residentialAddress: Address2ComponentValue,
  privacyConsentObtained: boolean,
  propertyOwner: PropertyOwnerWithAddressValue,
  email: string,
  mobileNumber: string,
  personalAssets: PersonalAssetsValue,
  personalLiabilities: PersonalLiabilitiesValue,
};
export type IndividualGuarantor = IndividualGuarantorWithoutAddress | IndividualGuarantorWithAddress;
export type GuarantorValue = (IndividualGuarantor | EntityGuarantor)[] | null;
