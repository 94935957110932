import {TermRateForBusinessOverdraft} from "../../app-calculator";

export interface OverdraftPaymentBreakupDialogData {
  term: TermRateForBusinessOverdraft
}

export interface OverdraftPaymentBreakupDialogData2 {
  loadFn: () => OverdraftPaymentBreakupDialogData;
  isQuote: boolean;
  hideBrokerage: boolean;
}

export interface OverdraftPaymentBreakupDialogResult { acceptedQuotation: boolean };
