import {ValidEmailCheckFn} from '../../utils/utils';
import {AggregatorAndOriginatorListValueGetFn, CustomerListValueGetFn} from './aggregator-and-originator-list-component.domain';
import {Observable} from 'rxjs';
import {CreateBrokerUserData, CreateCustomerUserData, GetUserByEmailFn, PismoAccountMappingWithCustomerListFn, UpdateUserData} from '../auth-service.domain';
import {User} from '../types.domain';
import { PismoAccountMappingWithCustomer } from '../pismo.domain';

export type CreateCustomerUserFn = (user: CreateCustomerUserData) => Observable<User>;
export type CreateBrokerUserFn = (user: CreateBrokerUserData) => Observable<User>;
export type UpdateFn = (userId: number, data: UpdateUserData) => Observable<User| null>;


export interface AddCustomerUserFormDialogDialogData {
  // validEmailCheckFn: ValidEmailCheckFn;
  getUserByEmailFn: GetUserByEmailFn;
  getFn: CustomerListValueGetFn;
  // createUserFn: CreateCustomerUserFn;
  // updateUserFn: UpdateFn,
  getAllPismoWithCustomerAccountFn:PismoAccountMappingWithCustomerListFn;
}

export interface AddBrokerUserFormDialogDialogData {
  // addedUser?: AddUserFormDialog;
  // validEmailCheckFn: ValidEmailCheckFn;
  getUserByEmailFn: GetUserByEmailFn;
  getFn: AggregatorAndOriginatorListValueGetFn;
  createUserFn: CreateBrokerUserFn;
  updateUserFn: UpdateFn;
}

export interface AddCustomerUserFormDialogResult {
  valid: boolean;
  pismoCustomer:PismoAccountMappingWithCustomer | null;
}

export interface AddBrokerUserFormDialogResult {
  valid: boolean;
  //addedUser?: AddUserFormDialog; // available when valid
}
