import { AssetCategorySelectionValue, } from './asset-category-selection-component.domain';
import { AssetCategoryTypeSelectionValue, } from './asset-category-type-selection-component.domain';
import {TruckGrossVehicleWeight, RedbookSearchByVehiclesResponse, RedbookSearchByVehspecResponse} from '../../domain/redbook-service.domain';
import { AssetExistingFinanceCommitmentValue, AssetPurposeValue } from './asset-purpose-selection-component.domain';

export type AssetSelectionComponentValue = {
  category: Exclude<AssetCategorySelectionValue, null>,
  type: Exclude<AssetCategoryTypeSelectionValue, null>,
  year: string,
  make?: string,
  family?: string,
  model?: string,
  description: string,
  vehicle?: RedbookSearchByVehiclesResponse[number],
  vehicles?: RedbookSearchByVehiclesResponse,
  truckGrossVehicleWeight?: TruckGrossVehicleWeight,
  OtherCar: boolean,
  assetPurpose?:AssetPurposeValue | null,
  assetPurposeDescription?:string|null
  assetExistingFinanceCommitment?:AssetExistingFinanceCommitmentValue
  vehicletype?:string,
  rbid?:number,
  rbc?:number
  newprice?:number,
  month?:number,
  makecode?:string,
  importflag?:string,
  goodretail?: number,
  goodwhs?: number,
  avgretail?: number,
  avgwhs?: number,
  bodystyle?: string,
} | null;

