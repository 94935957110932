import { AssetTypeRateValue } from './asset-type-rate-component.domain';
import { MinMaxRateValue } from './min-max-rate-component.domain';

export type ConsumerRateCardValue = ConsumerRateCard | null;
export interface ConsumerRateCard {
  carsAndCaravans: MinMaxRateValue,
  motorbikes: MinMaxRateValue,
  solarSystems: MinMaxRateValue,
  eotAge: MinMaxRateValue,
  loanTerms: MinMaxRateValue,
  adverseOnFile: number,
  privateSale: number,
  privateSaleDocFee: number,
  noPropertyOwnership: number,
  lowEquifaxScore: number,
  assetConditionUsed: number,
  hybrid: number,
  docFee: MinMaxRateValue,
  assetType: AssetTypeRateValue,
  monthlyAccountKeepingFee: number,
  maxBrokerRateDiscount: number,
  creditAssitanceFee: number,
  ppsrFee: number,
}
