import {MinMaxRateValue} from './component/min-max-rate-component.domain';
import {AssetCategoryRateValue} from './component/asset-category-rate-component';
import {AssetTypeRateValue} from './component/asset-type-rate-component.domain';

import {Operator} from '../app-calculator';
import {SortTypes} from './types.domain';
import {OriginatorRelationshipValue} from './component/originator-relationship-component.domain';
import {TransactionTypeRateValue} from './component/transaction-type-rate-component.domain';
import {SecurityTypeRateValue} from "./component/security-type-rate-component.domain";

// export type RatecardListSortType = {
//   dir: 'asc' | 'desc',
//   prop: 'RatecardId' | 'Name' | 'CreateTime'
// }[] | null

export type RatecardListSortType = SortTypes<'RatecardId' | 'Name' | 'CreateTime'>;
// NOTE: DEPRECATED not used
// export type AggregatorListSortType = SortTypes<'EntityName' | 'ABN' | 'Email' | 'TelephoneNumber'>;
export type OriginatorListSortType = SortTypes<'OriginatorBusinessId' | 'EntityName' | 'ABN' | 'Type' | 'SalesforceId'>;
export type AccreditationListingSortType = SortTypes<'companyName' | 'applicantNumber'>
export interface Ratecard {
  RatecardId: number,
  Name: string,
  CreateTime: string,
}

export type RatecardList = Ratecard;


export interface UpdateOriginatorInput {
  // Type?: OriginatorType,
  Relationship: OriginatorRelationshipValue,
  EntityName: string,
  EntityType: string,
  ABN: string,
  ACN: string,
  // Aggregator?: number,
  Website: string,
  Email: string,
  TelephoneNumber: string,
  RatecardId?: number,
  PercentagePaid: number,
}

export interface AssetFinanceTier1Range {
  AssetCondition: {Type: 'New' | 'Used', Rate: string}[];
  YearRanges: {
    YearRange: string;
    Operator: Operator;
    Threshold: string;
    YearRangeType: 'Low' | 'Medium' | 'High';
    Rate: string;
  }[];
}

export interface AssetFinanceTier2Range {
  AssetCondition: {Type: 'New' | 'Used', Rate: string}[];
  YearRanges: {
    YearRange: string;
    Operator: Operator;
    Threshold: string;
    YearRangeType: 'Low' | 'Medium' | 'High' | 'VeryHigh';
    Rate: string;
  }[];
}

export interface AssetFinanceTier1 {
  YearRangeThresholds: string[];
  AssetBacked: AssetFinanceTier1Range;
  // NotAssetBacked: AssetFinanceTier1Range;
}

export interface AssetFinanceTier2 {
  YearRangeThresholds: string[];
  AssetBacked: AssetFinanceTier2Range;
  // NotAssetBacked: AssetFinanceTier2Range;
}

export interface AssetFinanceTier3 {
  AssetBacked: {Type: 'New' | 'Used', Rate: string}[];
}

export interface AssetFinanceTier4 {
  AssetBacked: {Type: 'New' | 'Used', Rate: string}[];
}


export interface UpdateRatecardInput {
  Name: string,  // rate card name
  AssetFinance: { ApplicationType: 'AssetFinance' } & RateCardInputData,
  BusinessLoans: {ApplicationType: 'BusinessLoans' } & RateCardInputData,
  BusinessOverdraft: {ApplicationType: 'BusinessOverdraft' } & RateCardInputData,
  CorporateLoans: {ApplicationType: 'CorporateLoans' } & RateCardInputData,
  InsurancePremium: { ApplicationType: 'InsurancePremium' } & RateCardInputData,
  Consumer: { ApplicationType: 'Consumer' } & RateCardInputData,
}

export interface RateCardInputData {
  BaseInterestRate?: number,
  LoanAmount?: MinMaxRateValue,
  AssetCategory?: AssetCategoryRateValue,
  AssetType?: AssetTypeRateValue,
  EOTAge?: MinMaxRateValue,
  LoanTerms?: MinMaxRateValue,
  Brokerage?: MinMaxRateValue,
  AdversOnFile?: number,
  CarsPropertyOwner?: number,
  NoPropertyOwnership?: number,
  CapRate?: number,
  Truck12T?: number,
  NoAssetBacked?: number,
  TransactionTypeRate?: TransactionTypeRateValue,
  PrivateSaleDocFee?: number,
  PrivateSale?: number,
  DocFee?: MinMaxRateValue,
  AssetConditionUsed?: number,
  LowEquifaxScore?: number,
  LowDepositUplift?: number,
  ABNorGSTAge?: MinMaxRateValue,
  AssetFinanceTier1?: AssetFinanceTier1 | null,
  AssetFinanceTier2?: AssetFinanceTier2 | null,
  AssetFinanceTier3?: AssetFinanceTier3 | null,
  AssetFinanceTier4?: AssetFinanceTier4 | null,
  PropertyOwner?:number;
  NoPropertyOwner?:number;
  FacilityFee?: number;
  BrokerShareFacilityFee?: number;
  DirectorScoreRate?: number;
  CarsAndCaravans?: MinMaxRateValue;
  Motorbikes?: MinMaxRateValue;
  SolarSystems?: MinMaxRateValue;
  Hybrid?: number;
  NonPropertyOwnerBetween500And600Rate?: number;
  SecurityType?: SecurityTypeRateValue;
  MonthlyAccountKeepingFee?: number;
  MaxBrokerRateDiscount?: number;
  CreditAssitanceFee?: number;
  PpsrFee?: number;
}

// NOTE: use OriginatorBusiness
// export interface Originator extends OriginatorBusiness {
//   kind: 'Originator',
// }

export interface UpdateRatecardResult {
  ratecardId: number,
  ratecardDetailIds: number[],
}

