

export type PortalTheme = 'light' | 'dark';

export type PortalThemeServiceEvent = PortalThemeChangeServiceEvent;

export interface PortalThemeChangeServiceEvent {
  type: 'themeChange',
  portalTheme: PortalTheme
}

export type ChangePortalThemeFn = (theme: PortalTheme) => void;
