export interface CurrencyInputDialogData {
  title: string;
  value: number;
  nothingGreater: boolean;
  nothingLower: boolean;
}

export interface CurrencyInputDialogResult {
  valid: boolean;
  value: number;
}
