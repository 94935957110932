import { BusinessSearchValue } from "./business-search-component.domain";
import { EntityTypeValue } from "./entity-type-component.domain";
import { AddressComponentValue } from "./google-address-component.domain";
import { PrimaryIndustrySelectionValue } from "./primary-industry-selection-component.domain";
import { SecondaryIndustrySelectionValue } from "./secondary-industry-selection-component.domain";
import { YesNoValue } from "./yes-no-component.domain";
import {PolicyValue} from './policy-component.domain';
import {TrusteeValue} from './trustee-component.domain';
import {MemberValue} from './member-component.domain';
import { PartnerValue } from "./partner-component.domain";
import { DirectorValue } from "./director-component.domain";
import { SoleTraderValue } from "./sole-trader-component.domain";
import { AuthorisedSignatoryValue } from "./authorised-signatory-component.domain";
import { ContactValue } from "./select-contact-component.domain";
import { UserSelectionValue } from "./user-selection-component.domain";
import { ApplicationApplicant } from "../../domain/types.domain";
import { SalesforceContactSameAccountSelectionValue } from "./salesforce-contact-same-account-selection-component.domain";

export interface InsurancePremiumSummary {
  monthlyRepayment: string;
  applicationId: string;

  organisation: BusinessSearchValue;
  organisationType: EntityTypeValue;
  addtionalBrokerCorrespondent: UserSelectionValue;
  abn: string;
  acn?: string;

  annualRevenue: number;
  operateInCommercialPremises: YesNoValue;
  primaryIndustry: PrimaryIndustrySelectionValue,
  industrySector: SecondaryIndustrySelectionValue,
  primaryBusinessAddress: AddressComponentValue,
  businessLandline: string;

  policies: PolicyValue;

  applicants: Exclude<ApplicationApplicant, null>;

  authorisedSignatories: AuthorisedSignatoryValue;

  primaryContact: ContactValue;

  applicationNotes: string;
  brokerContact:SalesforceContactSameAccountSelectionValue
}
