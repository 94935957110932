import { Observable } from "rxjs"

export enum BpayPaymentType {
  DEBIT = '001',
  VISA = '101',
  MASTERCARD = '201',
  OTHER = '301',
}

export interface BpayAuthResult {
  access_token: string,
  token_type: string,
  expires_in: string,
}

export interface BpaySearchBillerResult {
  hasPrevPage: boolean,
  hasNextPage: boolean,
  billerSummaries: {
    billerCode: string,
    shortName: string,
    longName: string,
    acceptedPaymentMethods: string[],
    crnLocation: string,
  }[],
}

export type BpayGetBillerByBillerCodeResult = {
  billerCode: string,
  shortName: string,
  longName: string,
  industryANZASICCode: string,
  variableCrnIndicator: "Y" | "N",
  validCrnLengths: number[],
  acceptedPaymentMethods: string[],
} | null;

export interface BpayValidatePaymentResult {
  isValidationSuccessful: boolean,
  validationErrorMessage: string,
  validationSummaries: {
    validationResponseCode: string,
    validationErrorDescription: string,
  }[]
}

export interface BpayValidationData {
  BillerCode: string,
  CRN: string,
  Amount: number,
  paymentType?: BpayPaymentType,
  settlementDate?: string,
  paymentDate?: string,
}

// NOTE: DEPRECATED replaced with BpayValidationPaymentResult
// NOTE: to be removed only after multiple releases of mobile-app
export interface BpayValidationResult {
  Result: string,
  Message: string,
  // NOTE: never used
  // Id: string,
  // Type: string,
  // CreatedOn: string,
  // CreatedByFullName: string | null,
  // CreatedByWorkpool: string | null,
  // ModifiedOn: string,
  // ModifiedByFullName: string | null,
  // IsNew: boolean,
  // IsModified: boolean,
  // IsDeleted: boolean,
  // IsCopied: boolean,
  // IsExisting: boolean
}

export interface BpayComponentValue {
  BillerCode: string,
  BillerName: string,
  CRN: string,
  Amount: number,
}

export type GetBillerNameFn = (billercode: string) => Observable<BpayGetBillerByBillerCodeResult>;
export type ValidateBPAYFn = (data: BpayValidationData) => Observable<BpayValidatePaymentResult>;

