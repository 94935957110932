import { Address2, AppDocument } from './application-service.domain';
import { ApiResponse, AzureStorageDocument, Base64File, EntityType } from './types.domain';
import { Address2ComponentValue } from './component/address2-component.domain';
import { Observable } from 'rxjs';
import { BusinessSearchValue } from './component/business-search-component.domain';
import { YesNoValue } from './component/yes-no-component.domain';
import { Disbursement } from './component/ppsr-asset-component.domain';
import { LocalBankAccountDetailValue } from '../domain/direct-credit.domain';

export type CreateApplicationNoteFn = (data: CreateApplicationNoteBody) => Observable<ApiResponse>

export interface SettlementAssetDetailsSupplier {
  SupplierType: string,
  ABN: string,
  SupplierName: string,
  SupplierAddress: Address2,
  Contact: string,
  isManualSupplier: boolean,
  SupplierId: string,
  SalesForceId:string
}

export interface PpsrRegistrationInfoAfterSubmission {
  B2GMailboxPdfMessage: {
    PdfFileName: string,
    MessagePdf: string,
  }
}

export interface PpsrAsset {
  ApplicationId?: number|null,
  AssetId?: number|undefined|null,
  SalesForceId?: string|undefined|null,
  AssetSFRes?: {
    referenceId: string,
    id?: string,
    errors: {
      statusCode: string,
      message: string,
    }[]
  } | string | null |undefined,
  submittedByBroker?: boolean |undefined |null,
  submittedByAdmin?: boolean|undefined |null,
  isAssetSubmitted?: boolean|undefined |null,
  invoiceNumber?: string |undefined |null,
  PPSRStatus?:string |undefined |null;
  status?:string |undefined |null;
  taxInvoices?: AzureStorageDocument[] | undefined | null;
  SettlementAssetDetails?: {
    assetId?: string,
    assetCategory: string,
    year: number,
    assetType: string,
    description: string,
    supplierPPSRStatus?: 'unchecked' | 'issue' | 'confirmed' | 'registered',
    SerialNumberType: 'Chassis Number' | 'VIN' | 'Motor Vehicle Manufacturers Number';
    serialNumberType?: 'Chassis Number' | 'VIN' | 'Motor Vehicle Manufacturers Number';
    rego?: string,
    supplier?: string | AssetSupplier;
    serialNumber: string,
    InvoicePrice: number,
    depositAmount: number,
    // WEB-3702
    depositAmountDynamoney: number,
    depositAmountSupplier: number,
    amountFinanced: number,
    residual?: number,
    GST: number,
    autoGenerateInspection?: boolean;
    Supplier: {
      SupplierType: string,
      ABN: string,
      SupplierName: string,
      SupplierAddress: Address2,
      Contact: string,
      isManualSupplier: boolean,
      SupplierId: string,
      SalesForceId:string
    }
    VehicleDetails?: {
      BodyType: string,
      ManufactureYear: string,
      Make: string,
      Colour: string,
      EngineNumber: string,
      Model: string,
      VehicleType: string,
      ComplianceYearMonth: string,
      ResultCount: string,
      IdentifierNumber:string,
      RegistrationPlateNumber:string,
      RegistrationExpiry:string,
      Identifiertype:string,
      StateVehicleRegistered:string
    }
  }|undefined|null,
  PrivateSellerDetails?: PrivateSeller | undefined | null; // todo: this is not string, it is a json object that needs to be returned and parsed in the API level
}

export interface PpsrDocument {
  name: string,
  size: number,
  type: string,
  isPPSRDoc: boolean,
  salesforceFileId: string,
}

export interface PpsrData {
  ApplicationId: number,
  PPSRResponse: {
    CustomersRequestMessageId: string,
    CustomersUserDefinedFields: string,
    PpsrRequestMessageId: string,
    RequestProcessedByEnvironment: string,
    RequestProcessedDateTime: string,
  },
  companySummary: {
    Account: number,
    MotorVehicleCount: number,
    AllPapNoExceptCount: number,
    OtherGoodsCount: number,
  },
  Grantors: ({
    type: 'ABN',
    OrganisationNumber: string,
    OrganisationNumberType: 'ABN'
  } | {
    type: 'ACN',
    OrganisationNumber: string,
    OrganisationNumberType: 'ACN'
  } | {
    type: 'Individual',
    FamilyName: string,
    GivenNames: string,
    DateOfBirth: string
  })[],
  isCompanySearch: boolean,

}

export interface PpsrDataRaw {
  ApplicationId: number;
  PPSRResponse?: string;
  companySummary?: string;
  Grantors: string;
  isCompanySearch?: boolean;
}

export interface AssetSupplier {
  AssetSupplierId: number,
  SupplierType: string | null,
  ABN: string,
  ACN?: string,
  SupplierAddress: Address2 | null,
  Contact: string | null,
  SalesForceId: string,
  SupplierName: string,
  isDeleted: boolean,
  isAccredited?: boolean,
  isPrivateSeller?: boolean,
  FinancialInstitution: string,
  BankAccountName: string,
  BankAccountBsb: string,
  BankAccountNumber: string,
}

export interface VehicleDetails {
  BodyType: string,
  ManufactureYear: string,
  Make: string,
  Colour: string,
  EngineNumber: string,
  Model: string,
  VehicleType: string,
  ComplianceYearMonth: string,
  ResultCount: string,
  NoVehicleInfo?: boolean;
  IdentifierNumber:string,
  RegistrationPlateNumber:string,
  RegistrationExpiry:string,
  Identifiertype:string,
  StateVehicleRegistered:string
}

export interface PrivateSeller {
  name: string,
  middleName?: string,
  lastName: string,
  email: string;
  mobile: string;
  address: Address2ComponentValue,
  business: BusinessSearchValue,
  state: string;
  yesNo: YesNoValue, //haveAbn
  SalesForceId?: string,
  bankDetails: LocalBankAccountDetailValue,
}

export type PrivateSellerFormValue = PrivateSeller | null;

// NOTE: replaced with PpsrAsset
// export interface ApplicationAsset {
//   Application: number;
//   AssetId: number;
//   SettlementAssetDetails: string;
//   SalesForceId: number;
//   isAssetSubmitted: boolean;
//   AssetSFRes: string;
//   submittedByBroker: boolean;
//   submittedByAdmin: boolean;
// }

// NOTE: replaced with
// export interface PpsrDocumentRaw {
//   Documents: string;
// }

export interface ReleasePPSRData {
  registrationNumber: string,
  changeNumber: string,
}

export interface ReleasePPSRResult {
  Error?: string,
  CustomersRequestMessageId: string,
  PpsrRequestMessageId: number,
  RequestProcessedByEnvironment: string,
  RequestProcessedDateTime: string, // YYYY-MM-DDTHH:MM:SS
  ChangeNumber: number,
  PpsrTransactionId: number,
  RegistrationEndTime: string, // YYYY-MM-DDT:HH:MM:SS
  RegistrationNumber: string,
}

export interface SubmitPPSRResult {
  Error?: string,
  CustomersRequestMessageId: string,
  PpsrRequestMessageId: number,
  RequestProcessedByEnvironment: string,
  RequestProcessedDateTime: string, // YYYY-MM-DDTHH:MM:SS
  Registrations: {
    RegistrationInfo: {
      ChangeNumber: number,
      IsRegistrationEndTimeChanged: boolean,
      NewRegistrationSequenceNumber: number,
      PpsrTransactionId: number,
      RegistrationEndTime: string, // YYYY-MM-DDTHH:MM:SS
      RegistrationNumber: string,
      RegistrationStartTime: string, // YYYY-MM-DDTHH:MM:SS
      B2GMailboxPdfMessage: {
        B2GMailboxMessageId: number,
        MessagePdf: string,
        PdfFileName: string,
      }
    }[]
  }
}

export interface PpsrSubmissionData {
  ID: string|undefined;
  Type: 'Consumer' | 'Commercial';
  Grantors: ({
    OrganisationNumber?: string|undefined;
    OrganisationNumberType?: 'ABN' | 'ACN';
  } | {
    FamilyName?: string|undefined;
    GivenNames: string|undefined;
    DateOfBirth: string|undefined; /*YYYY-MM-DDT00:00:00*/
  })[];
  Assets: ({
    CollateralClassType: 'MotorVehicle',
    SerialNumberType: string|undefined | null/* no space*/,
    SerialNumber: string|undefined | null,
    RegistrationEndTime: string |undefined/*YYYY-MM-DDT23:59:59*/,
    CollateralDescription: string|undefined | null
  } | {
    CollateralClassType: 'OtherGoods', RegistrationEndTime: string|undefined /*YYYY-MM-DDT23:59:59*/
  })[];
}

export interface SubmitPPSRResult {
  Error?: string,
  CustomersRequestMessageId: string,
  PpsrRequestMessageId: number,
  RequestProcessedByEnvironment: string,
  RequestProcessedDateTime: string, // YYYY-MM-DDTHH:MM:SS
  Registrations: {
    RegistrationInfo: {
      ChangeNumber: number,
      IsRegistrationEndTimeChanged: boolean,
      NewRegistrationSequenceNumber: number,
      PpsrTransactionId: number,
      RegistrationEndTime: string, // YYYY-MM-DDTHH:MM:SS
      RegistrationNumber: string,
      RegistrationStartTime: string, // YYYY-MM-DDTHH:MM:SS
      B2GMailboxPdfMessage: {
        B2GMailboxMessageId: number,
        MessagePdf: string,
        PdfFileName: string,
      }
    }[]
  }
}


export interface FileData {
  Title: string;
  PathOnClient: string;
  Description: string;
  FirstPublishLocationId: string | null | undefined;
  VersionData: string;
}

export interface ApplicationAssetData {
  ApplicationId: number;
  AssetId?: number;
  SalesForceId: string | null;
  SettlementAssetDetails: {
    assetCategory: string,
    assetType: string,
    description: string,
    serialNumberType?: 'Chassis Number' | 'VIN' | 'Motor Vehicle Manufacturers Number' | null,
    serialNumber: string,
    InvoicePrice: number,
    GST: number,
    year: number,
    supplierPPSRStatus: 'unchecked' | 'issue' | 'confirmed' | 'registered',
    supplier: string | AssetSupplier,
    VehicleDetails?: {
      BodyType: string,
      ManufactureYear: string,
      Make: string,
      Colour: string,
      EngineNumber: string,
      Model: string,
      VehicleType: string,
      ComplianceYearMonth: string,
      ResultCount: string,
      IdentifierNumber: string,
      RegistrationPlateNumber: string,
      RegistrationExpiry: string,
      Identifiertype: string,
      StateVehicleRegistered: string
    }
  };
  isAssetSubmitted: boolean;
  submittedByBroker: boolean;
  submittedByAdmin: boolean;
  AssetSFRes?: string;
  status: string;
  PrivateSellerDetails: PrivateSeller;
}

export interface VinAssetSearchResult {
  CustomersRequestMessageId: string,
  PpsrRequestMessageId: number,
  RequestProcessedByEnvironment: string,
  RequestProcessedDateTime: string,
  PpsrTransactionId: number,
  SearchCriteria: {
    IncludeArchived: boolean,
    IncludeCurrent: boolean,
    IncludeExpired: boolean,
    IncludeRemoved: boolean,
    SerialNumber: string,
    SerialNumberType: "VIN" | "MotorVehicleManufacturersNumber" | "ChassisNumber",
  },
  SearchResult: {
    NevdisData: {
      NevdisVehicles: {
        NevdisVehicle: {
          StolenDetails: string | null,
          VehicleDetail: {
            Jurisdiction: string,
            JursdictionParticipation: boolean,
            Registration: {
              ExpiryDate: string,  // eg. 27 Feb 2018
              PlateNumber: string,
            },
            VehicleDescription: {
              BodyType: string,
              Colour: string,
              ComplianceYearMonth: string | null,
              EngineNumber: string,
              Make: string,
              ManufactureYear: string,
              Model: string,
              VehicleType: string,
            }
          },
          VehicleIdentifier: {
            IdentifierType: "VIN",
            IdentifierValue: string,
          },
          WrittenOffDetails: string | null,
        }[],
      },
      VerificationStatus: string,
    },
    ResultDetails: {
      ResultDetail: {
        ChangeHistory: {
          ChangeDetails: {
            ChangeDetail: {
              ChangeNumber: number,
              ChangeType: string,
              RegistrationChangeTIme: string, // eg. 2022-05-31T19:34:53
            }[]
          }
        },
        RegistrationDetail: {
          AddressForService: {
            Addressee: string,
            EmailAddress: string,
            FaxNumber: string | null,
            MailingAddress: {
              CountryName: string,
              "ISO3166CountryCode": string,
              "Line1": string,
              "Line2": string | null,
              "Line3": string | null,
              "Locality": string,
              "Postcode": string,
              "State": string,
            },
            "PhysicalAddress": {
              "CountryName": string | null,
              "ISO3166CountryCode": string | null,
              "Line1": string | null,
              "Line2": string | null,
              "Line3": string | null,
              "Locality": string | null,
              "Postcode": string | null,
              "State": string | null
            }
          },
          "AreAssetsSubjectToControl": null,
          "AreProceedsClaimed": string, // "True"
          "Attachments": string | null,
          "ChangeNumber": number,
          "CollateralClassDescription": string,
          "CollateralClassType": string,
          "CollateralDescription": string | null,
          "CollateralType": string, // "Commercial",
          "EarlierRegistrationNumber": string | null,
          "GivingOfNoticeIdentifier": string | null,
          "Grantors": {
            "GrantorSearchDetail": [
              {
                "GrantorType": string, // "Organisation",
                "Individual": {
                  "FamilyName": string | null,
                  "GivenNames": string | null
                },
                "Organisation": {
                  "OrganisationName": string,  //"BAB Test",
                  "OrganisationNumber": string | null,
                  "OrganisationNumberType": string | null
                }
              }
            ]
          },
          "IsInventory": string, // "False",
          "IsMigrated": boolean,
          "IsPMSI": string, // "False",
          "IsSecurityInterestRegistrationKind": boolean,
          "IsSubordinate": boolean,
          "IsTransitional": boolean,
          "MigrationDetail": {
            "OriginalStartDate": string | null,
            "SourceRegister": string | null
          },
          "ProceedsClaimedDescription": string, // "All present and after acquired property.",
          "RegistrationChangeTime": string,  // "2022-05-31T18:34:53",
          "RegistrationEndTime": string,  // "2028-05-27T23:59:59",
          "RegistrationKind": string,  // "SecurityInterest",
          "RegistrationNumber": string, // "202105270000656",
          "RegistrationStartTime": string, // "2021-05-27T11:48:00",
          "SecuredParties": {
            "CollateralRegistrationSecuredParty": [
              {
                "Individual": {
                  "FamilyName": string | null,
                  "GivenNames": string | null
                },
                "Organisation": {
                  "OrganisationName": string | null,  // "BENDIGO AND ADELAIDE BANK LIMITED",
                  "OrganisationNumber": string | null, // "068 049 178",
                  "OrganisationNumberType": string | null, // "ACN"
                },
                "SecuredPartyType": string, // "Organisation"
              }
            ]
          },
          "SerialNumberDetails": {
            "AdditionalAircraftDetails": {
              "AircraftNationality": string | null,
              "AircraftNationalityCodeAndRegistrationMark": string | null,
              "ManufacturersModel": string | null,
              "ManufacturersName": string | null
            },
            "AdditionalVehicleDetails": {
              "VehicleDescriptiveText": string | null,
              "VehicleRegistrationNumber": string | null
            },
            "SerialNumber": string, // "1A8FCE7V26Y169388",
            "SerialNumberType": string,  // "VIN"
          }
        },
        "RestrictionDetail": {
          "IsAccessRestricted": string | null,
          "IsArchived": string | null,
          "IsRemoved": string | null,
          "RegistrationNumber": string | null,
          "RemovalDetail": string | null,
          "RestrictedAccessDetail": string | null
        },
        "ResultSequenceNumber": number
      }[]
    }
  }
}

// export interface ChassisNumberSearchResult {
//   CustomersRequestMessageId: string,
//   PpsrRequestMessageId: number,
//   RequestProcessedByEnvironment: string,
//   RequestProcessedDateTime: string,
//   PpsrTransactionId: number,
//   SearchCriteria: {
//     IncludeArchived: boolean,
//     IncludeCurrent: boolean,
//     IncludeExpired: boolean,
//     IncludeRemoved: boolean,
//     SerialNumber: string,
//     SerialNumberType: "ChassisNumber",
//   },
//   SearchResult: {
//     NevdisData: {
//       NevdisVehicles: {
//         NevdisVehicle: {
//           StolenDetails: string | null,
//           VehicleDetail: {
//             Jurisdiction: string,
//             JursdictionParticipation: boolean,
//             Registration: {
//               ExpiryDate: string,  // eg. 27 Feb 2018
//               PlateNumber: string,
//             },
//             VehicleDescription: {
//               BodyType: string,
//               Colour: string,
//               ComplianceYearMonth: string | null,
//               EngineNumber: string,
//               Make: string,
//               ManufactureYear: string,
//               Model: string,
//               VehicleType: string,
//             }
//           },
//           VehicleIdentifgier: {
//             IdentifierType: "VIN",
//             IdentifierValue: string,
//           },
//           WrittenOffDetails: string | null,
//         }[],
//       },
//       VerificationStatus: string,
//     },
//     ResultDetails: {
//       ResultDetail: {
//         ChangeHistory: {
//           ChangeDetails: {
//             ChangeDetail: {
//               ChangeNumber: number,
//               ChangeType: string,
//               RegistrationChangeTIme: string, // eg. 2022-05-31T19:34:53
//             }[]
//           }
//         },
//         RegistrationDetail: {
//           AddressForService: {
//             Addressee: string,
//             EmailAddress: string,
//             FaxNumber: string | null,
//             MailingAddress: {
//               CountryName: string,
//               "ISO3166CountryCode": string,
//               "Line1": string,
//               "Line2": string | null,
//               "Line3": string | null,
//               "Locality": string,
//               "Postcode": string,
//               "State": string,
//             },
//             "PhysicalAddress": {
//               "CountryName": string | null,
//               "ISO3166CountryCode": string | null,
//               "Line1": string | null,
//               "Line2": string | null,
//               "Line3": string | null,
//               "Locality": string | null,
//               "Postcode": string | null,
//               "State": string | null
//             }
//           },
//           "AreAssetsSubjectToControl": null,
//           "AreProceedsClaimed": string, // "True"
//           "Attachments": string | null,
//           "ChangeNumber": number,
//           "CollateralClassDescription": string,
//           "CollateralClassType": string,
//           "CollateralDescription": string | null,
//           "CollateralType": string, // "Commercial",
//           "EarlierRegistrationNumber": string | null,
//           "GivingOfNoticeIdentifier": string | null,
//           "Grantors": {
//             "GrantorSearchDetail": [
//               {
//                 "GrantorType": string, // "Organisation",
//                 "Individual": {
//                   "FamilyName": string | null,
//                   "GivenNames": string | null
//                 },
//                 "Organisation": {
//                   "OrganisationName": string,  //"BAB Test",
//                   "OrganisationNumber": string | null,
//                   "OrganisationNumberType": string | null
//                 }
//               }
//             ]
//           },
//           "IsInventory": string, // "False",
//           "IsMigrated": boolean,
//           "IsPMSI": string, // "False",
//           "IsSecurityInterestRegistrationKind": boolean,
//           "IsSubordinate": boolean,
//           "IsTransitional": boolean,
//           "MigrationDetail": {
//             "OriginalStartDate": string | null,
//             "SourceRegister": string | null
//           },
//           "ProceedsClaimedDescription": string, // "All present and after acquired property.",
//           "RegistrationChangeTime": string,  // "2022-05-31T18:34:53",
//           "RegistrationEndTime": string,  // "2028-05-27T23:59:59",
//           "RegistrationKind": string,  // "SecurityInterest",
//           "RegistrationNumber": string, // "202105270000656",
//           "RegistrationStartTime": string, // "2021-05-27T11:48:00",
//           "SecuredParties": {
//             "CollateralRegistrationSecuredParty": [
//               {
//                 "Individual": {
//                   "FamilyName": string | null,
//                   "GivenNames": string | null
//                 },
//                 "Organisation": {
//                   "OrganisationName": string | null,  // "BENDIGO AND ADELAIDE BANK LIMITED",
//                   "OrganisationNumber": string | null, // "068 049 178",
//                   "OrganisationNumberType": string | null, // "ACN"
//                 },
//                 "SecuredPartyType": string, // "Organisation"
//               }
//             ]
//           },
//           "SerialNumberDetails": {
//             "AdditionalAircraftDetails": {
//               "AircraftNationality": string | null,
//               "AircraftNationalityCodeAndRegistrationMark": string | null,
//               "ManufacturersModel": string | null,
//               "ManufacturersName": string | null
//             },
//             "AdditionalVehicleDetails": {
//               "VehicleDescriptiveText": string | null,
//               "VehicleRegistrationNumber": string | null
//             },
//             "SerialNumber": string, // "1A8FCE7V26Y169388",
//             "SerialNumberType": string,  // "VIN"
//           }
//         },
//         "RestrictionDetail": {
//           "IsAccessRestricted": string | null,
//           "IsArchived": string | null,
//           "IsRemoved": string | null,
//           "RegistrationNumber": string | null,
//           "RemovalDetail": string | null,
//           "RestrictedAccessDetail": string | null
//         },
//         "ResultSequenceNumber": number
//       }[]
//     }
//   }

// }
export interface AssetSearchBody {
  SearchNumber: string |undefined;
  NumberType: 'ChassisNumber' | 'VIN' | 'MotorVehicleManufacturersNumber' | string|undefined;
  SearchType: 'G' | 'R'; // 'SearchByGrantor' | 'SearchBySerialNumber';
}

export interface AssetSearchResponse {
  SearchResult: any;
  SearchSummary: any;
}

export interface UpdateDocumentsBody {
  remove: AppDocument[],
  new: Base64File[],
  isPPSRDoc?: boolean,
}

export type SearchGrantorsResult = any;


export interface SaveSupplierData {
  businessAddress: Address2ComponentValue,
  abn: string,
  Products: {
    assetFinance: boolean,
    tradeFinance: boolean,
    businessLoan: boolean,
    insurancePremium: boolean,
  },
  entityType: EntityType,
  natureofBusiness: 'supplier',
  entityName: string,
  officeNumber: string,
  heldAccreditationBefore: boolean,
  ispartOfgroupOrAggregator: boolean,
  hasAnotherName: boolean,
  hasAustralianCreditLicence: boolean,
  declaredBankrupt: boolean,
  refusedAccreditation: boolean,
  convictedOfFraud: boolean,
}

export interface SaveSupplierResult {
  Oppid: string;
  Accountid: string;
}

export interface SendAssetToSfRecord {
  attributes: {
    type: 'Asset',
    referenceId: string,
  },
  Name: string,
  Asset_Category__c: string,  // assetCategoryIndex
  Asset_Class__c: string,     // assetCategoryIndex-assetTypeIndex
  Opportunity__c: string,     // Application.AppInfo.SalesforceId
  Description: string,
  Invoice_Price_incl_GST__c: number,
  GST__c: number,
  Supplier__c: string,        // Supplier's SalesforceId
  SerialNumber: string,
  Make__c?: string,
  Model__c?: string,
  Manufacture_Year__c?: string,
  Invoice_Number__c: string;
  Residual__c: number,
  Registration_Number__c: string,
  // WEB-3702
  Deposit_to_Dyna__c: number,
  Deposit_to_Supplier__c: number,
}

export interface SendAssetToSfData {
  records: SendAssetToSfRecord[];
}

export interface SettleLoanBody {
  assets: PpsrAsset[];
  disbursements: Disbursement[];
  salesforceId: string;
}

export interface SendAssetToSFResult {
  referenceId: string,  // assetId
  id?: string,
  errors?: {
    statusCode: string,
    message: string,
  }[]
}

export interface RequestDoc {
  data: {
    stageName: string
  },
  object: string,
  id: string
}

export interface CreateApplicationNoteBody {
  UserId: number,
  ApplicationId: number,
  Note: string,
  visibleToInternalStaff: boolean
}

export interface SearchSupplierBody {
  accreditedOnly: boolean;
}
