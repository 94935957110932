import {Address2ComponentValue, } from './address2-component.domain';
import {  AddressComponentValue, } from './google-address-component.domain';
import {  CurrencyCode} from './currency-selection-component.domain';

export type NewSupplierValue = NewCommercialSupplierValue | NewPrivateSupplierValue | null  ;

export interface NewCommercialSupplierValue {
  type: 'commercial';
  supplierName: string;
  supplierAddress: Address2ComponentValue;
  supplierEmail: string;
  businessNumber: string;
  currencyCode: CurrencyCode;
  bankAccount: BankAccount;
}

export interface NewPrivateSupplierValue {
  type: 'private';
  supplierName: string;
  supplierAddress: Address2ComponentValue;
  mobileNumber: string;
  phoneNumber: string;
}

export type BankAccount = InternationalBankAccount | DomesticBankAccount 


export interface InternationalBankAccount {
  type: 'international';
  bankName: string;
  swiftCode: string;
  AccountName: string;
  AccountNumber: string;
  iban: string;
  address: AddressComponentValue;
}

export interface DomesticBankAccount {
  type: 'domestic';
  InstitutionName: string,
  AccountName: string,
  BSB: string,
  AccountNumber: string,
}

