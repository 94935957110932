import _ from "lodash";

const OVERRIDE = [
  'Password',
  'password',
  'access_token',
  'accessToken',
  'AccessToken',
  'refresh_token',
  'refreshToken',
  'RefreshToken',
];

export const sensitiveDataMasking = <T = any>(o: T) => {
  const obj = _.cloneDeep(o);
  return _sensitiveDataMasking(obj, 0, 5);
}

const _sensitiveDataMasking = <T = any>(obj: T, depth: number, maxDepth: number): T => {
  depth += 1;
  if (depth > maxDepth) {
    return obj;
  }
  if (obj) {
    if (typeof obj == 'object') {
      for (const key in obj) {
        const v = _.get(obj, key);
        if (typeof v == 'object') { // array is object
          _sensitiveDataMasking(v, depth, maxDepth);
        } else {
          if (OVERRIDE.includes(key)) {
            _.set(obj, key, '****');
          }
        }
      }
    }
  }
  return obj;
}
