export interface SuccessDialogData {
  message: string,
  subMessage: string,
}

export interface SuccessDialogResult {

}


export interface SuccessConfirmationDialogData {
  message: string,
  subMessage: string,
}

export interface SuccessConfirmationDialogResult {
  readyForSubmission: boolean;
}
