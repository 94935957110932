import {GenderValue, } from './gender-component.domain';
import {  PropertyOwnerWithAddressValue, } from './property-owner-with-address-component.domain';
import {  TitleSelectionValue, } from './title-selection-component.domain';
import {  YesNoValue} from './yes-no-component.domain';
import { Address2ComponentValue } from './address2-component.domain';
import {Moment} from 'moment';

export type SoleTraderValue = {
  kind: 'SoleTrader',
  title: TitleSelectionValue,
  firstName: string,
  lastName: string,
  gender: Exclude<GenderValue, null>,
  dob: Moment,
  residentialAddress: Address2ComponentValue,
  privacyConsentObtained: Exclude<YesNoValue, null>,
  propertyOwner: Exclude<PropertyOwnerWithAddressValue, null>,
  guarantor: Exclude<YesNoValue, null>,
  email: string,
  mobile: string,
} | null;

