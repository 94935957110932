import { Observable } from "rxjs"
import { ApiResponse } from "./types.domain"

export interface PismoAccountNotes {
  AccountNotesId: number,
  AccountNumber: number,
  DateGenerated: string,
  Note: string,
  UserId: number,
  Name: string,
  Email:string
}

export type RemovePismoAccountNoteByNoteIdFn = (noteId: number) => Observable<ApiResponse>

