import {MinMaxRateValue} from './min-max-rate-component.domain';

export type BusinessLoanRateCardValue = BusinessLoanRateCard | null;
export interface BusinessLoanRateCard {
  loanAmount: MinMaxRateValue,
  loanTerms: MinMaxRateValue,
  brokerage: MinMaxRateValue,
  adverseOnFile: number,
  docFee: MinMaxRateValue,
  gstAge: MinMaxRateValue,
  propertyOwner: number,
  noPropertyOwner: number,
  directorScoreRate: number,
  nonPropertyOwnerBetween500And600Rate: number
}
