export type NotificationResponse = {
  totalUnread: number;
  notifications: Notifications;
}

export interface GetNotificationResult {
  records: RawNotification[],
  total_notification: number;
  unread_notification: number;
}

export interface ReadNotification {
  id: number,
  notification_id: number,
  user_id: number
}

export type Notifications = (UserNotification | CompanyNotification | BroadcastNotification)[];

export interface UserNotification {
  id: number,
  message_title: string,
  type: 'USER',
  user_id: number,
  recieve_time: string,
  description: string,
  readId: boolean
}

export interface CompanyNotification {
  id: number,
  message_title: string,
  type: 'COMPANY',
  recieve_time: string,
  description: string,
  company_id: number,
  readId: boolean,
}

export interface BroadcastNotification {
  id: number,
  message_title: string,
  type: 'BROADCAST',
  recieve_time: string,
  description: string,
  readId: boolean,
}

export interface RawNotification {
  id: number,
  message_title: string,
  type: 'USER' | 'COMPANY' | 'BROADCAST',
  user_id: number | null,
  recieve_time: string,
  description: string,
  company_id: number | null,
  readId: number | null,
}

export type NotificationSubmission = UserNotificationSubmission | BroadcastNotificationSubmission | CompanyNotificationSubmission;

export interface UserNotificationSubmission {
  type: 'USER',
  user_id: number,
  message_title: string,
  description: string,
}

export interface BroadcastNotificationSubmission {
  type: 'COMPANY',
  company_id: number,
  message_title: string,
  description: string,
}

export interface CompanyNotificationSubmission {
  type: 'BROADCAST',
  message_title: string,
  description: string,
}

