import {Observable} from "rxjs";
import {
  ApiResponse,
  ConsumerDscrCalculatorInput,
  ConsumerDscrCalculatorValue,
  ConsumerFinanceDscrServiceabilityCalculationInput,
  ConsumerFinanceDscrServiceabilityCalculationResult,
} from "@portal-workspace/grow-shared-library";

export type GetConsumerDscrCalculatorValueFn =  (applicationId: number) => Observable<ConsumerDscrCalculatorValue>;
export type UpdateConsumerDscrCalculatorValueFn = (applicationId: number, v: ConsumerDscrCalculatorInput) => Observable<ApiResponse>;
export type ConsumerFinanceDsrServiceabliltyCalculationFn = (input: ConsumerFinanceDscrServiceabilityCalculationInput) => Observable<ConsumerFinanceDscrServiceabilityCalculationResult>

