import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InputMaskModule } from '@ngneat/input-mask';
import { CustomerApplicantComponent } from './customer-applicant.component'
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { DirectiveLibraryModule } from '../../directives/directive-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        CustomerApplicantComponent
    ],
    exports: [
        CustomerApplicantComponent
    ]
})
export class CustomerApplicantModule {

}
