import {  GenderValue, } from './gender-component.domain';
import {  PersonalAssetsValue, } from './personal-assets-component.domain';
import {  PersonalLiabilitiesValue, } from './personal-liabilities-component.domain';
import {  PropertyOwnerWithAddressValue, } from './property-owner-with-address-component.domain';
import {  TitleSelectionValue, } from './title-selection-component.domain';
import {  YesNoValue } from './yes-no-component.domain';
import { Address2ComponentValue } from './address2-component.domain';

import {Moment} from 'moment';



export type DirectorValueWithPropertyOwnerAddress = {
  kind: 'Director',
  type: 'WithPropertyAddress',
  title: TitleSelectionValue;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: Moment,
  gender: GenderValue,
  residentialAddress: Address2ComponentValue,
  privacyConsentObtained: Exclude<YesNoValue, null>,
  propertyOwner: PropertyOwnerWithAddressValue,
  // propertyValue: number,
  // mortgageBalance: number,
  guarantor: YesNoValue,
  email: string,
  mobileNumber: string,
  personalAssets: PersonalAssetsValue,
  personalLiabilities: PersonalLiabilitiesValue,
};


export type DirectorValueWithoutPropertyOwnerAddress = {
  kind: 'Director',
  type: 'WithoutPropertyAddress',
  title: TitleSelectionValue;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: Moment,
  gender: GenderValue,
  residentialAddress: Address2ComponentValue,
  privacyConsentObtained: Exclude<YesNoValue, null>,
  propertyOwner: YesNoValue,
  // propertyValue: number,
  // mortgageBalance: number,
  guarantor: YesNoValue,
  email: string,
  mobileNumber: string,
  personalAssets: PersonalAssetsValue,
  personalLiabilities: PersonalLiabilitiesValue,
};

export type DirectorValue =  (IndividualDirector)[] | null;
export type IndividualDirector =  DirectorValueWithPropertyOwnerAddress | DirectorValueWithoutPropertyOwnerAddress;

