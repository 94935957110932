import {Application, GetApplicationOwnerFn} from '../application-service.domain';
import {DeleteContractBody, GenerateContractResponse} from '../docusign-service.domain';
import {Observable} from 'rxjs';
import {ApiResponse} from '../types.domain';
import {GroupedDocument} from './document-details-component.domain';
import {TermRateForBusinessOverdraft} from '../../app-calculator';
import { GetUserFn } from '../auth-service.domain';

export type GenerateApprovalNoticeFn = (body: GenerateApprovalNoticeBody) => Observable<GenerateContractResponse>;
export type SendApprovalNoticeEmailFn = (body: SendApprovalNoticeEmailBody) => Observable<ApiResponse>;
export type DeleteApprovalNoticeFileFn = (body: DeleteContractBody) => Observable<ApiResponse>;
export interface ApprovalNoticeTemplateData {
  currentDate: string;
  expiryDate: string; // 120 days after current date
  customer: {
    name: string;
    address: string;
    mobile: string;
    email: string;
    abn: string;
    acn: string;
  };
  consumer: {
    name: string;
    address: string;
    mobile: string;
    email: string;
  };
  type: string; // rental | chattel
  transactionType: string;
  limit: string; // invoice price? amount field in SF - in currency
  term: string; // 24 months
  rate: string; // 6%
  repayment: string;
  deposit: string;
  balloon: string;
  asset: string; // if car - make + model + spec; otherwise only asset spec - only the asset in application
  brokerage: string;
  brokerOriginationFee: string;
  guarantors: string[]; // Joe \n ABC pty ltd (if someone marked as others, only display the company name)
  security: string; // the same as the contract
  docFeeFinanced: boolean;
  docFee: string;
  brokeragePercent: string;
  standardCondition: string[];
  nonStandardCondition: string[];
  limitInclBrokerage: string;
  amountOver250k: boolean;
  availableToClient: string;
  facilityEstablishmentFee: string;
  applicationId: string;
  nonStandardConditionExists: boolean;
  apr: string;
}

export interface GenerateApprovalNoticeBody {
  application: Application;
  standardCondition: string[];
  nonStandardCondition: string[];
  businessLoanData: TermRateForBusinessOverdraft | undefined;
}

export interface ApprovalNoticeDialogData {
  application: Application;
  generateApprovalNoticeFn: GenerateApprovalNoticeFn;
  sendApprovalNoticeEmailFn: SendApprovalNoticeEmailFn;
  getApplicationOwnerFn: GetApplicationOwnerFn;
  deleteApprovalNoticeFileFn: DeleteApprovalNoticeFileFn;
  getUserFn: GetUserFn;
  apiUrl: string;
  standardCondition: GroupedDocument[];
  nonStandardCondition: GroupedDocument[];
  businessLoanData: TermRateForBusinessOverdraft | undefined;
}

export interface ApprovalNoticeDialogResult {

}

export interface SendApprovalNoticeEmailBody {
  fileName: string;
  emailList: string[];
  ccEmailList: string[];
  application: Application;
  userId: number;
}
