import {Component} from '@angular/core';


@Component({
    selector: 'asset-make',
    templateUrl: './asset-make.component.html',
    styleUrls: ['./asset-make.component.scss'],
    standalone: true
})
export class AssetMakeComponent {

}
