import {PaymentFrequencyType} from "./component/payment-frequency-selection-component.domain";
import {AssetConditionType} from "./component/asset-condition-component.domain";
import {AssetSelectionComponentValue} from "./component/asset-selection-component.domain";
import {LoanTermType} from "./component/loan-terms-selection-component.domain";
import {FinanceType} from "./component/finance-type-component.domain";
import {BalloonPaymentType} from "./component/balloon-payment-component.domain";
import {BrokerageSelectionType} from "./component/brokerage-selection-component.domain";
import {BusinessSearchValue} from "./component/business-search-component.domain";
import {RatecardDetails, SearchCompanyByABNResult} from "./application-service.domain";
import {TransactionType, TransactionValue} from "./component/transaction-type-component.domain";
import {TotalPaymentBreakupDialogData} from "./component/total-payment-breakup-dialog.domain";
import {AppCalculator, TermRate, TermRateForBusinessOverdraft, TermRateForCorporateLoan} from "../app-calculator";
import {SecurityTypeSelectionType} from "./component/security-type-selection-component.domain";
import {LtvSelectionType} from "./component/ltv-selection-component.domain";


export interface CalculateAssetFinanceEstmationResult {
  terms: TermRate,
  calculator: AppCalculator,
  repayment: number,
  totalPaymentBreakupDialogData: TotalPaymentBreakupDialogData
}

export interface CalculateBusinessLoanEstimationResult {
  terms: TermRate,
  calculator: AppCalculator,
  repayment: number,
  totalPaymentBreakupDialogData: TotalPaymentBreakupDialogData
}

export interface CalculateConsumerAssetFinanceEstimationResult {
  terms: TermRate,
  calculator: AppCalculator,
  repayment: number,
  totalPaymentBreakupDialogData: TotalPaymentBreakupDialogData
};

export interface CalculateBusinessOverdraftEstimationResult {
  term: TermRateForBusinessOverdraft,
  calculator: AppCalculator,
};

export interface CalculateCorporateLoanEstmationResult {
  term: TermRateForCorporateLoan,
  calculator: AppCalculator,
}


export type AppCalculatorRouteData =
  Omit<AppCalculatorAssetFinanceData, 'businessSearchValue' | 'rateCard'> & {ABN: string, rateCardId: number} |
  Omit<AppCalculatorConsumerAssetFinanceData, 'businessSearchValue' | 'rateCard'> & {ABN?: string, rateCardId: number} |
  Omit<AppCalculatorBusinessLoanData, 'businessSearchValue' | 'rateCard' > & {ABN?: string, rateCardId: number} |
  Omit<AppCalculatorBusinessOverdraftData, 'rateCard'> & {rateCardId: number}
;

export type AppCalculatorAssetFinanceData = {
  type: 'AssetFinance',
  paymentFrequencyType: PaymentFrequencyType | null,
  assetConditionType: AssetConditionType | null,
  assetSelectionValue:
    Pick<Exclude<AssetSelectionComponentValue, null>,
    'category' | 'type' | 'model' | 'year' | 'truckGrossVehicleWeight'> &
    {vehicles?: { goodretail?: number, newprice?: number}[]} &
    {vehicle?: { goodretail?: number, newprice?: number }},
   // (Omit<Exclude<AssetSelectionComponentValue, null>, 'vehicle' | 'vehicles'> & {vehicles?: { goodretail?: number, newprice?: number}[]} & { vehicle?: { goodretail?: number, newprice?: number } }) | null, //
  assetYear: number,
  loanTermType: LoanTermType | null,
  financeType: FinanceType | null,
  balloonPaymentType: BalloonPaymentType | null,
  brokerageType: BrokerageSelectionType | null,
  loanAmount: number,
  businessSearchValue: {type: 'search-result', result?: Pick<SearchCompanyByABNResult, 'ABNAgeMonths' | 'GSTAgeMonths'>} | null, // BusinessSearchValue, //
  existingApplicationBureauReport: Pick<SearchCompanyByABNResult, 'ABNAgeMonths' | 'GSTAgeMonths'> | null, // SearchCompanyByABNResult | null, //
  propertyOwnership: boolean | null,
  docFeeFinanced: boolean | null,
  brokerOriginationFee: number,
  brokerage: number,
  adverseOnFile: boolean | null,
  equifaxScoreAbove600: boolean | null,
  privateSaleOrLeaseback: boolean | null,
  balloonPayment: number,
  rateCard: RatecardDetails | null,
  invoiceAmount: number,
  deposit: number,
  brokerageAmount?: number,
  docFee?: number,
  balloonPaymentAmount?: number,
  paymentType?: string,
  transactionType?: TransactionType,
  creditRateAdjustment?: number,
}

export type AppCalculatorConsumerAssetFinanceData = {
  type: 'Consumer',
  paymentFrequencyType: PaymentFrequencyType | null,
  assetConditionType: AssetConditionType | null,
  assetSelectionValue:
    Pick<Exclude<AssetSelectionComponentValue, null>,
      'category' | 'type' | 'model' | 'year' | 'truckGrossVehicleWeight'> &
    {vehicles?: { goodretail?: number, newprice?: number}[]} &
    {vehicle?: { goodretail?: number, newprice?: number }},
    // Omit<Exclude<AssetSelectionComponentValue, null>, 'vehicle'> & { vehicle?: { goodretail?: number, newprice?: number } } | null, //
  assetYear: number,
  loanTermType: LoanTermType | null,
  financeType: FinanceType | null,
  balloonPaymentType: BalloonPaymentType | null,
  brokerageType: BrokerageSelectionType | null,
  loanAmount: number,
  businessSearchValue: {type: 'search-result', result: Pick<SearchCompanyByABNResult, 'ABNAgeMonths' | 'GSTAgeMonths'>} | null, // BusinessSearchValue, //
  existingApplicationBureauReport: Pick<SearchCompanyByABNResult, 'ABNAgeMonths' | 'GSTAgeMonths'> | null, // SearchCompanyByABNResult | null, //
  propertyOwnership: boolean,
  docFeeFinanced: boolean,
  brokerOriginationFee: number,
  brokerage: number,
  adverseOnFile: boolean,
  equifaxScoreAbove600: boolean,
  privateSaleOrLeaseback: boolean,
  balloonPayment: number,
  rateCard: RatecardDetails | null,
  invoiceAmount: number,
  deposit: number,
  balloonPaymentAmount?: number,
  paymentType?: string,
  brokerageAmount?: number,
  docFee?: number,
  creditRateAdjustment?: number,
  hybrid?: boolean,
  ppsrFee?: number,
  rateDiscount?: number,
  percentagePaidToDealerOrBroker?: number,
}

export type AppCalculatorBusinessLoanData = {
  type: 'BusinessLoans',
  paymentFrequencyType: PaymentFrequencyType | null,
  loanTermType: LoanTermType | null,
  brokerageType: BrokerageSelectionType | null,
  loanAmount: number,
  businessSearchValue: {type: 'search-result', result?: Pick<SearchCompanyByABNResult, 'ABNAgeMonths' | 'GSTAgeMonths'>} | null, // BusinessSearchValue, //
  propertyOwner: boolean,
  brokerage?: number,
  adverseOnFile: boolean,
  lowEquifaxScore: boolean,
  directorScore: boolean,
  rateCard: RatecardDetails | null,
  bureauReport: Pick<SearchCompanyByABNResult, 'ABNAgeMonths' | 'GSTAgeMonths'> | null, // SearchCompanyByABNResult | null, //
  brokerageAmount?: number,
  docFeeFinanced?: boolean,
  docFee?: number,
  paymentType?: string,
  creditRateAdjustment?: number,
  hideBrokerage?: boolean,
}


export type AppCalculatorBusinessOverdraftData = {
  type: 'BusinessOverdraft',
  loanTermType: LoanTermType | null,
  // loanAmount: number,
  propertyOwner: boolean,
  adverseOnFile: boolean,
  lowEquifaxScore: boolean,
  directorScore: boolean,
  rateCard: RatecardDetails | null,
  facilityLimit: number,
  facilityEstablishmentFeePercent: number,
  brokerageAmount?: number,
  docFee?: number
  creditRateAdjustment?: number,
}


export type AppCalculatorCorporateLoanData = {
  type: 'CorporateLoan',
  loanTermType: LoanTermType | null,
  // loanAmount: number,
  propertyOwner: boolean,
  adverseOnFile: boolean,
  lowEquifaxScore: boolean,
  directorScore: boolean,
  rateCard: RatecardDetails | null,
  facilityLimit: number,
  facilityEstablishmentFeePercent: number,
  brokerageAmount?: number,
  docFee?: number
  creditRateAdjustment?: number,
  securityType?: SecurityTypeSelectionType,
  ltvType?: LtvSelectionType,
}
