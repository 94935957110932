import {Moment} from 'moment';

export interface DateRangeDialogData {
  message?: string;
  mandatory: boolean;
}
export type DateRangeDialogResult =  DateRangeDialogOkResult | DateRangeDialogCancelResult;
export type DateRangeDialogOkResult = {
  type: 'ok',
  startDate: Moment  | null,
  endDate: Moment  | null,
  // isChecked: boolean
}
export interface DateRangeDialogCancelResult {
  type: 'cancel',
}
