import {SearchCompanyByABNResult} from '../application-service.domain';
import {EntityTypeValue} from './entity-type-component.domain';

export type BusinessSearch2ResultValue = {
  type: 'search-result',
  organisationName: string;
  abn: string;
  acn: string;
  status: string;
  stateCode: string;
  postcode: string;
  result?: SearchCompanyByABNResult,
  entityTypeValue: EntityTypeValue,
}

export type BusinessSearch2FreeTextValue = {
  type: 'free-text',
  organisationName: string,
}


export type BusinessSearch2Value = BusinessSearch2ResultValue | BusinessSearch2FreeTextValue | null;

