import {Observable} from 'rxjs';
import {User} from '../types.domain';

export type UpdateUserPasswordAdminFn = (userId: number, data: { password: string}) => Observable<User | null>;

export interface ConfirmPasswordAdminDialogData {
  userId: number;
  updateUserPasswordFn: UpdateUserPasswordAdminFn;
  // oldPassword?: string
  // confirmPassword? : string
}

export interface ConfirmPasswordAdminDialogResult {
  valid: boolean
}
