export interface AlertDialogData {
  message: string,
  subMessage: string,
}

export interface AlertDialogResult {

}


export interface ConfirmationDialogData {
  message: string,
  subMessage: string,
}

export interface ConfirmationDialogResult {
  readyForSubmission: boolean;
}


export interface YesNoDialogData {
  message: string,
  subMessage: string,
}

export interface YesNoDialogResult {
  readyForSubmission: boolean;
}