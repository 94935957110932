export type WidgetTitles = 'Application' | 'In Progress' | 'Settlements' | 'Approval Rate';

export interface WidgetDetails {
  title: WidgetTitles,
  col1: { title: string, value: number | string },
  col2: { title: string, value: number | string },
  col3: { title: string, value: number | string },
  percentage: any,
  icon: string,
  iconColor: string
}
