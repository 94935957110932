import { Observable } from 'rxjs';
import {AccessLevel, Customer, User} from './types.domain';

export type Add2faSecretKeyFn = (email: string, password: string) => Observable<Add2faSecretKeyPayload>;
export type EnableDisable2faFn = (email: string, flag: 0 | 1) => Observable<EnableDisable2FA>;
export type MatchConfirmPasswordFn = (email: string, password: string) => Observable<MatchPassword>;
export type Get2faTotpVerificationCodeUriFn = ()=>Observable<string>;
export type Verify2faTokenFn = (digit: number)=>Observable<Verify2faTokenPayload>;

export interface Add2faSecretKeyPayload {
  status: boolean,
  user: User | null
};

export interface MatchPassword {
  status: boolean,
  user: User | null
};

export interface UpdateIpToken {
  ipToken : string
}

export interface EnableDisable2FA{
  user : User | null ,
  is2FA : boolean,
}

export interface Verify2faTokenPayload {
  status: boolean,
  user: User | null
}
