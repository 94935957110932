export interface InformationDialogData {
    message: string,
    subMessage: string,
}
export interface InformationDialogResult {
  type: 'close' | 'reset';
}
export interface InformationConfirmationDialogData {
    message: string,
    subMessage: string,
}
export interface InformationConfirmationDialogResult {
    readyForSubmission: boolean;
}
