import {EntityTypeValue} from '../component/entity-type-component.domain';
import {TitleSelectionValue} from '../component/title-selection-component.domain';
import {GenderValue} from '../component/gender-component.domain';
import {Moment} from 'moment';
import {Address2ComponentValue} from '../component/address2-component.domain';
import { PropertyOwnerWithAddressValue } from '../component/property-owner-with-address-component.domain'
import {YesNoValue} from '../component/yes-no-component.domain';
import { PersonalAssetsValue } from '../component/personal-assets-component.domain';
import { PersonalLiabilitiesValue } from '../component/personal-liabilities-component.domain';
import {BusinessNumberSearchValue} from './business-number-search-component.domain';

export type EntityTrustee = {
  kind: 'Trustee',
  type: 'Entity',
  organisation: Exclude<BusinessNumberSearchValue, null>,
  organisationType: Exclude<EntityTypeValue, null>,
  acn: string,
  abn: string,
  manualEntry: boolean,
  // businessName: string,
  // stepacn: string
};
export type IndividualTrustee = {
  kind: 'Trustee',
  type: 'Individual',
  title: TitleSelectionValue,
  firstName: string,
  lastName: string,
  middleName: string,
  gender: GenderValue,
  dob: Moment,
  residentialAddress: Address2ComponentValue,
  privacyConsentObtained: Exclude<YesNoValue, null>,
  propertyOwner: PropertyOwnerWithAddressValue,
  email: string,
  mobileNumber: string,
  guarantor: YesNoValue,
  personalAssets: PersonalAssetsValue,
  personalLiabilities: PersonalLiabilitiesValue,
};
export type TrusteeValue = (IndividualTrustee | EntityTrustee)[] | null;
