import {
  PaginablePayloadApiResponse,
} from './types.domain';

import {Observable} from "rxjs";

export interface GetCustomersFn {
  (opts: {
    page: { offset: number, limit: number, },
    filter: string,
    sorts?: { prop: 'SalesforceId' | 'ABN' | 'ACN' | 'EntityName' | '', dir: 'ASC' | 'DESC' }[],
  }): Observable<PaginablePayloadApiResponse<SearchedCustomer>>;
}

export interface SearchedCustomer {
  ACN: string,
  CustomerId: number,
  EntityName: string,
  SalesforceId: string,
}

export interface SalesforceCustomer {
  // UserId: number,
  CustomerId: number,
  LimitAmount: number,
  EntityName: string,
  ABN: string,
  ACN: string,
  Email: string,

  AccountBSB: string,
  AccountName: string,
  AccountNumber: string,
  GivenName: string,
  FamilyName: string,
  MobileNumber: string,
  // PhoneNumber: string,
}
