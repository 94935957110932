import {FormControl} from "@angular/forms";
import {CorporateLoanPaymentBreakupDialogData} from "./corporate-loan-payment-breakup-dialog.domain";


export interface CorporateLoanPricingComparisonDialogData {
  currentValue: CorporateLoanPaymentBreakupDialogData,
  newValue: CorporateLoanPaymentBreakupDialogData,
}

export interface CorporateLoanPricingComparisonDialogResult {
  acceptedQuotation: boolean;
  docFee: number,
}


export type CorporateLoanPricingComparisonDialogDatasource  = ({
  name: string,
  currentValue: string | null | undefined,
  newValue: string | null | undefined,
  formControl?: FormControl<string | null>, // if exists, field is editable
  type: 'text',
  currentValueClick?: (currentValue: string | null | undefined)=>void,
  newValueClick?: (newValue: string | null | undefined)=>void,
} | {
  name: string,
  currentValue: number | string | null | undefined,
  newValue: number | string | null | undefined,
  formControl?: FormControl<string | number | null>, // if exists, field is editable
  type: 'percentage',
  currentValueClick?: (currentValue: string | number | null | undefined)=>void,
  newValueClick?: (newValue: string | number | null | undefined)=>void,
} | {
  name: string,
  currentValue: number | string | null | undefined,
  newValue: number | string | null | undefined,
  formControl?: FormControl<string | number | null>, // if exists, field is editable
  type: 'currency',
  currentValueClick?: (currentValue: string | number | null | undefined)=>void,
  newValueClick?: (newValue: string | number | null | undefined)=>void,
})[]
