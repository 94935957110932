
export interface ManualBusinessSearchDialogData {
    acnMandatory: boolean;
}

export interface ManualBusinessSearchDialogResult {
    organisationName: string,
    abn: string,
    acn: string,
}
