import {Base64File} from '../../domain/types.domain';

export type DocumentUploadDialogResult =  DocumentUploadDialogFacilityDocResult  | DocumentUploadDialogInvoiceDocResult;

export interface DocumentUploadDialogFacilityDocResult {
  documentType: 'facilityDocument';
  documents: Base64File[];
}

export interface DocumentUploadDialogInvoiceDocResult {
  documentType: 'invoiceDocument';
  floorplanDrawdownId: number;
  documents: Base64File[];
}
