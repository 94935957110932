
import {  Address2, } from '../application-service.domain';
import {  AssetCategorySelectionValue, } from './asset-category-selection-component.domain';
import {  AssetCategoryTypeSelectionValue} from './asset-category-type-selection-component.domain';
import { AssetSupplier, PrivateSeller, SettleLoanBody, VehicleDetails } from '../ppsr-service.domain';
import { GetInstitutionNameFn } from '../../domain/direct-credit.domain';
import { Observable } from 'rxjs';
import {Address2ComponentValue} from '../component/address2-component.domain';
import {ApiResponse, AzureStorageDocument} from '../../domain/types.domain';
import {BusinessSearchValue} from '../component/business-search-component.domain';


export type CreateNewDisbursementFn = (disbursement: Disbursement) => Observable<number>;
export type UpdateDisbursementFn = (disbursement: Disbursement) => Observable<ApiResponse>;
export type GetDisbursementByApplicationIdFn = <D extends Disbursement>(applicationId: number) => Observable<D[]>;
export type SyncDisbursementToSfFn = (body: SyncDisbursementBody) => Observable<ApiResponse>;
export type SearchAccreditedSupplierFn = (abn: string, accreditedOnly?: boolean)=> Observable<AssetSupplier[]>;
export type SyncBankDetailsToSfFn = (body: SyncSupplierBankDetailsBody) => Observable<ApiResponse>;
export type SyncPrivateSellerBankDetailsToSfFn = (body: SyncSupplierBankDetailsBody) => Observable<ApiResponse>;
export type SyncDepositPaidToSfFn = (body: SyncDepositPaidToBody) => Observable<ApiResponse>;

export type SettleLoanFn = (body: SettleLoanBody) => Observable<ApiResponse>;
export interface PpsrSupplier{
  SupplierType: string | null,
  ABN?: string | null,
  ACN?: string | null,
  SupplierName: string,
  SupplierAddress: Address2,
  Contact: string,
  isManualSupplier?: boolean,
  isExistingSupplier?: boolean,
  SupplierId?: string,
  SalesForceId?: string
}

export type PpsrAssetValue = {
  assetCategory: AssetCategorySelectionValue,
  assetType: AssetCategoryTypeSelectionValue,
  description: string,
  serialNumberType: string,
  serialNumber: string,
  year: number | null,
  invoicePrice: number,
  depositAmount?: number,
  depositAmountDynamoney?: number,
  depositAmountSupplier?: number,
  amountFinanced?: number,
  residual?: number,
  salesforceId: string | null,
  invoiceNumber?: string,
  PrivateSellerDetails?: PrivateSeller,
  gst: number,
  supplier: PpsrSupplier | undefined,
  AssetId: number | null
  vehicleDetails?: VehicleDetails | null,
  taxInvoices?: AzureStorageDocument[] | undefined | null,
  assetNumber: number | null;
  PPSRStatus?: string|null,
  rego?: string,
  autoGenerateInspection?: boolean | null,
  SettlementAssetDetails?: {
    assetId?: string,
    assetCategory: string,
    year: number,
    assetType: string,
    description: string,
    SerialNumberType: 'Chassis Number' | 'VIN' | 'Motor Vehicle Manufacturers Number';
    serialNumberType?: 'Chassis Number' | 'VIN' | 'Motor Vehicle Manufacturers Number';
    rego?: string,
    supplier?: AssetSupplier;
    serialNumber: string,
    InvoicePrice: number,
    GST: number,
    autoGenerateInspection?: boolean | null,
    supplierPPSRStatus?: 'unchecked' | 'issue' | 'confirmed' | 'registered',
    Supplier: {
      SupplierType: string,
      ABN: string,
      SupplierName: string,
      SupplierAddress: Address2,
      Contact: string,
      isManualSupplier: boolean,
      SupplierId: string,
      SalesForceId: string
    }
    VehicleDetails?: {
      BodyType: string,
      ManufactureYear: string,
      Make: string,
      Colour: string,
      EngineNumber: string,
      Model: string,
      VehicleType: string,
      ComplianceYearMonth: string,
      ResultCount: string,
      IdentifierNumber: string,
      RegistrationPlateNumber: string,
      RegistrationExpiry: string,
      Identifiertype: string,
      StateVehicleRegistered: string
    }
  }
} | null;

export type PrivateSellerValue = {
  PrivateSellerDetails: PrivateSellerDetails
}

export interface PrivateSellerDetails {
  firstName: string,
  middleName: string,
  lastName: string,
  privateSellerAddress: Address2,
  financialInstitutionName: string,
  accountName: string,
  bsb: number,
  accountNumber: string,
  name?: string,
  business?: BusinessSearchValue,
  mobile: number,
  email: number,
  SalesForceId?: string,
}

export type Disbursement = AssetDisbursement | BusinessDisbursement;
export interface AbstractDisbursement {
  id: number;
  applicationId: number;
  lastUpdatedTime: string;
  isDeleted: boolean;
  // WEB-3702: syncToSF: boolean;
  // payee: DisbursementPayee | null;
  // invoices: DisbursementInvoice[] | BusinessDisbursementInvoice;
  amount: number;
  bankDetails: DisbursementBankDetails | null;
  salesforceId: string | null;
  newDisbursement?: boolean;
  // WEB-3702: depositPaidTo?: 'Dynamoney' | 'Supplier' | null;
}

export interface AssetDisbursement extends AbstractDisbursement {
  i: 'asset-disbursement';
 payee: DisbursementAssetPayee | null;
  invoices: DisbursementInvoice[]
}

export interface BusinessDisbursement extends AbstractDisbursement {
  i: 'business-disbursement';
  payee: DisbursementBusinessPayee | null;
  invoices: BusinessDisbursementInvoice | null;
}

export type DisbursementPayee = DisbursementAssetPayee | DisbursementBusinessPayee;
export interface DisbursementBusinessPayee { // for business loans / business overdraft
  i: 'disbursement-business-payee',
  supplier?: AssetSupplier,
  customerSfId?: string,
}
export interface DisbursementAssetPayee {  // for asset finance
  i: 'disbursement-asset-payee',
  type: "supplier" | "private-seller" | "manual";
  supplier?: AssetSupplier;
  privateSeller?: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    privateSellerAddress?: Address2ComponentValue;
    business?: BusinessSearchValue;
    SalesForceId?: string
  };
  payeeManual?: string;
}

export interface DisbursementInvoice {
  invoiceNumber: string;
  invoiceAmount: number;
  invoiceGst: number;
  depositAmount: number;
  depositAmountDynamoney: number;
  depositAmountSupplier: number;
}

export interface BusinessDisbursementInvoice {
  invoiceNumber: string;
}

export interface DisbursementBankDetails {
  paymentType: DisbursementPaymentType;
  bpayBillerCode?: string;
  bpayCrn?: string;
  bpayBillerName?: string;
  bpayAmount?: number
  financialInstitution?: string;
  bankAccountName?: string;
  bankAccountBsb?: string;
  bankAccountNumber?: string;
}

export type DisbursementPaymentType = "direct-debit" | "bpay";

export interface DisbursementDialogData {
  type: 'Default' | 'Add'
}

export interface DisbursementDialogResult {
  readyForSubmission: boolean,
  // WEB-3702: depositPaidTo: 'Dynamoney' | 'Supplier'
}

export interface BusinessDisbursementPayeeDialogData {
  customerSfId: string;
  searchSupplierFn: any;
  allPayees: DisbursementBusinessPayee[];
  currentPayee: DisbursementBusinessPayee | null;
}

export type BusinessDisbursementPayeeDialogResult = {
  type: 'submit',
  payee: {
    type: 'customer',
    customerSfId: string
  } | {
    type: 'supplier',
    supplier: AssetSupplier
  }
} | {
  type: 'cancel',
}

export interface BusinessDisbursementInvoiceDialogData {
  allInvoices: DisbursementInvoice[];
  currentInvoices: BusinessDisbursementInvoice | null;
}

export type BusinessDisbursementInvoicesDialogResult = {
  type: 'submit',
  invoices: BusinessDisbursementInvoice,
} | {
  type: 'cancel',
}

export interface AssetDisbursementPayeeDialogData {
  allPayees: DisbursementAssetPayee[];
  currentPayee: DisbursementAssetPayee | null;
}

export interface AssetDisbursementPayeeDialogResult {
  readyForSubmission: boolean;
  payee: DisbursementAssetPayee;
}

export interface AssetDisbursementInvoicesDialogData {
  allInvoices: DisbursementInvoice[];
  currentInvoices: DisbursementInvoice[];
}

export interface AssetDisbursementInvoicesDialogResult {
  readyForSubmission: boolean;
  invoices: DisbursementInvoice[];
}

// export interface BusinessDisbursementInvoicesDialogResult {
//   readyForSubmission: boolean;
//   invoices: DisbursementInvoice[];
// }

export interface DisbursementBankDetailsDialogData {
  bankDetails: DisbursementBankDetails | null;
  getBillerNameFn: any;
  getInstitutionNameFn: GetInstitutionNameFn;
  amount?: number | null;
}

export interface DisbursementBankDetailsDialogResult {
  readyForSubmission: boolean;
  bankDetails: DisbursementBankDetails;
}

export interface DisbursementAmountDialogData {
  amount: number;
  bankDetails?: any;
}

export interface DisbursementAmountDialogResult {
  readyForSubmission: boolean;
  amount: number;
}

export interface SyncSupplierBankDetailsBody {
  salesforceId: string;
  financialInstitution: string;
  bankAccountName: string;
  bankAccountBsb: string;
  bankAccountNumber: string;
}

export interface SyncPrivateSellerBankDetailsBody {
  salesforceId: string;
  financialInstitution: string;
  bankAccountName: string;
  bankAccountBsb: string;
  bankAccountNumber: string;
}

export interface SyncDisbursementBody {
  disbursement: Disbursement;
  salesforceId: string;
}

export interface SyncDepositPaidToBody {
  depositPaidTo: 'Dynamoney' | 'Supplier';
  salesforceId: string;
}

// NOTE: not needed anymore -> replaced with PpsrData
// export type PpsrDetail = {
//   ApplicationId: number;
//   PPSRResponse: string;
//   companySummary: string;
//   Grantors: string;
//   isCompanySearch: boolean;
// }

