import {Moment} from 'moment';
import { AddressComponentValue, } from './google-address-component.domain';
import {  BusinessSearchValue, } from './business-search-component.domain';
import {  ContactValue, SelectContactValue, } from './select-contact-component.domain';
import {  DirectorValue, } from './director-component.domain';
import {  EntityTypeValue, } from './entity-type-component.domain';
import { GuarantorValue, } from './guarantor-component.domain';
import {  LoanTermValue, } from './loan-terms-selection-component.domain';
import {  MemberValue, } from './member-component.domain';
import {  PartnerValue, } from './partner-component.domain';
import {  PrimaryIndustrySelectionValue, } from './primary-industry-selection-component.domain';
import {  ReferenceValue, } from './reference-component.domain';
import {  SecondaryIndustrySelectionValue, } from './secondary-industry-selection-component.domain';
import {  SoleTraderValue, } from './sole-trader-component.domain';
import {  TrusteeValue, } from './trustee-component.domain';
import {  YesNoValue } from './yes-no-component.domain';
import { FinanceTypeValue } from './finance-type-component.domain';
import { LoanPurposeValue } from './loan-purpose-selection-component.domain';
import { AssetSelectionComponentValue } from './asset-selection-component.domain';
import { AssetConditionValue } from './asset-condition-component.domain';
import { BrokerageSelectionValue } from './brokerage-selection-component.domain';
import { PaymentFrequencyValue } from './payment-frequency-selection-component.domain';

export interface SummaryValue {

  monthlyRepayment?: string;
  estimatedDrawDownDate?: Moment;

  applicationId?: string;
  organisation?: string,
  organisationType: EntityTypeValue;
  financeType?:  FinanceTypeValue;

  abn?: string;
  acn?: string;

  loanPurpose?: LoanPurposeValue;

  revenue?: string;
  limitRequest?: string;
  commercialPremises?: YesNoValue;

  primaryIndustry?: PrimaryIndustrySelectionValue;
  industrySector?: SecondaryIndustrySelectionValue;
  businessLandline?: string;
  primaryBusinessAddress?: AddressComponentValue;

  asset?: AssetSelectionComponentValue;
  assetCondition?: AssetConditionValue;

  invoiceAmount?: string;
  loanTerms?: LoanTermValue;
  privateSales?: YesNoValue;
  equifaxScoreAboveThreshold?: YesNoValue;
  adverseOnFile?: YesNoValue,
  propertyOwner?: YesNoValue,
  brokerage?: BrokerageSelectionValue,
  paymentFrequency?: PaymentFrequencyValue,
  docFee?: YesNoValue,
  brokerOriginationFee?: string;

  applicants?: (Exclude<TrusteeValue, null>[number] | Exclude<MemberValue, null>[number] | Exclude<PartnerValue, null>[number] | Exclude<DirectorValue, null>[number] | Exclude<SoleTraderValue, null>)[];
  guarantors?: GuarantorValue;

  background?: {
    whatDoesTheBusinessSell: string;
    whatDoesTheBusinessSellTo: string;
    whatDoesTheBusinessBuyFrom: string;
  };

  primaryContact?: SelectContactValue;
  references?: ReferenceValue;

  applicationNotes?: string;
}
