import {DigitalIdAuthenticationPayload} from "@portal-workspace/grow-shared-library";

export interface DigitalIdComponentEvent {
  transactionId: string;
  selectorId: string;
  authResult:
    | { type: 'error'; error: string; errorDescription: string }
    | { type: 'success'; payload: DigitalIdAuthenticationPayload };
}

export type DigitalIdOnCompleteCallbackFn = (evt: DigitalIdComponentEvent) => void;
