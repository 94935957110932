import {AssetCategoryTypeSelectionValue} from '../component/asset-category-type-selection-component.domain';
import {MobileValue} from '../component/mobile-component.domain';
import {SelectContactValue} from '../component/select-contact-component.domain';
import {AbnComponentValue} from '../component/abn-component.domain';
import {AcnComponentValue} from '../component/acn-component.domain';
import {ApplicationApplicant} from '../../domain/types.domain';
import {Moment} from 'moment';
import { Address2ComponentValue } from './address2-component.domain';
import { AggregatorSearchComponentValue } from './aggregator-search-component.domain';
import { BusinessSearchValue } from './business-search-component.domain';
import { EntityTypeValue } from './entity-type-component.domain';
import { GuarantorValue } from './guarantor-component.domain';
import { LoanTermValue } from './loan-terms-selection-component.domain';
import { PaymentFrequencyValue } from './payment-frequency-selection-component.domain';
import { PrimaryIndustrySelectionValue } from './primary-industry-selection-component.domain';
import { ReferenceValue } from './reference-component.domain';
import { SecondaryIndustrySelectionValue } from './secondary-industry-selection-component.domain';
import { YesNoValue } from './yes-no-component.domain';

export interface CommercialFinanceSummary {

  monthlyRepayment: string;
  estimatedDrawDownDate: Moment;
  applicationId: string;

  organisation: BusinessSearchValue;
  broker: AggregatorSearchComponentValue;

  invoiceAmount: string;
  depositAmount: string;
  loanAmount: string;
  loanTerms: LoanTermValue,
  paymentFrequency: PaymentFrequencyValue,
  propertyOwner: YesNoValue,

  assetCategoryType: AssetCategoryTypeSelectionValue;
  organisationType: EntityTypeValue,
  abn: AbnComponentValue;
  acn: AcnComponentValue;
  annualRevenue: string;
  operatesInCommercialPremises: YesNoValue,
  primaryIndustry: PrimaryIndustrySelectionValue,
  industrySector: SecondaryIndustrySelectionValue,
  address: Address2ComponentValue,
  businessLandline: MobileValue;

  applicants: Exclude<ApplicationApplicant, null>;

  guarantors: GuarantorValue;

  primaryContact: SelectContactValue;

  references: ReferenceValue;

  applicationNotes: string;
}
