import {Moment} from 'moment';
import { Address2ComponentValue } from './address2-component.domain';
import { AggregatorSearchComponentValue } from './aggregator-search-component.domain';
import { BrokerageSelectionValue } from './brokerage-selection-component.domain';
import { BusinessSearchValue } from './business-search-component.domain';
import { DirectorValue } from './director-component.domain';
import { EntityTypeValue } from './entity-type-component.domain';
import { GuarantorValue } from './guarantor-component.domain';
import { LoanPurposeValue } from './loan-purpose-selection-component.domain';
import { LoanTermValue } from './loan-terms-selection-component.domain';
import { MemberValue } from './member-component.domain';
import { PartnerValue } from './partner-component.domain';
import { PaymentFrequencyValue } from './payment-frequency-selection-component.domain';
import { PrimaryIndustrySelectionValue } from './primary-industry-selection-component.domain';
import { ReferenceValue } from './reference-component.domain';
import {SecondaryIndustrySelectionValue } from './secondary-industry-selection-component.domain';
import { ContactValue } from './select-contact-component.domain';
import { SoleTraderValue } from './sole-trader-component.domain';
import { TrusteeValue } from './trustee-component.domain';
import { UserSelectionValue } from './user-selection-component.domain';
import { YesNoValue } from './yes-no-component.domain';
import {ApplicationApplicant} from '../types.domain';
import { SalesforceContactSameAccountSelectionValue } from './salesforce-contact-same-account-selection-component.domain';
import { NumberInputValue } from './number-input-component.domain';
import {LoanTermsSelectionWithInputValue} from "./loan-terms-selection-with-input-component.domain";

export interface BusinessFinanceSummary {
  repayment: string;
  estimatedDrawDownDate: Moment;
  applicationId: string;

  organisation: BusinessSearchValue;
  addtionalBrokerCorrespondent?: UserSelectionValue;
  broker: AggregatorSearchComponentValue;

  loanPurpose: LoanPurposeValue;
  loanAmount: number;
  loanTerms: LoanTermsSelectionWithInputValue;
  brokerage: BrokerageSelectionValue;
  paymentFrequency: PaymentFrequencyValue;
  propertyOwner: YesNoValue;
  adverseOnFile: YesNoValue;
  equifaxScoreAboveThreshold: YesNoValue;
  organisationType: EntityTypeValue;
  abn: string;
  acn: string;
  annualRevenue: number;
  operatesInCommercialPremises: YesNoValue;
  primaryIndustry: PrimaryIndustrySelectionValue;
  industrySector: SecondaryIndustrySelectionValue;
  primaryBusinessAddress: Address2ComponentValue;
  businessLandline: string;

  applicants: Exclude<ApplicationApplicant, null>,

  guarantors: GuarantorValue;

  primaryContact: ContactValue;

  references: ReferenceValue;

  applicationNotes: string;
  directorScore: boolean;
  brokerContact:SalesforceContactSameAccountSelectionValue;
  docFeeFinanced: YesNoValue;
}
