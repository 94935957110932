import { Observable } from "rxjs"

export type LocalBankAccountDetailValue = {
  InstitutionName: string | null,
  AccountName: string | null,
  BSB: string | null,
  AccountNumber: string | null,
  Name?: string,
} | null;

export interface DirectCreditInstitutionSearchResult {
  InstitutionName: string,
  BSBName: string,
  BankNameCode: string,
}

export type GetInstitutionNameFn = (bsb: string) => Observable<DirectCreditInstitutionSearchResult | null>
export type GetBankDetailsFromOpportunitySfFn = (salesforceId: string) => Observable<LocalBankAccountDetailValue | null>;
