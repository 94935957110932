import {NotNullable} from '../../domain/types.domain';
import {AssetCategorySelectionValue} from './asset-category-selection-component.domain';
import {CurrencyInputValue} from './currency-input-component.domain';
import {AssetCategoryTypeSelectionValue} from './asset-category-type-selection-component.domain';

export type AssetComponentEvent = /*AssetComponentAddEvent |*/ AssetComponentRemoveEvent;

// export interface AssetComponentAddEvent {
//   type: 'Add'
// }

export interface AssetComponentRemoveEvent {
  type: 'Remove'
}

export interface SaveAssetEvent {
  asset: AssetComponentEventData,
}

export interface AssetComponentEventData {
  assetCategory: NotNullable<AssetCategorySelectionValue>,
  assetType: NotNullable<AssetCategoryTypeSelectionValue>,
  description: string,
  year: number,
  serialNumberType: string,
  serialNumber: string,
  invoicePrice: NotNullable<CurrencyInputValue>,
  gst: NotNullable<CurrencyInputValue>,
  assetId: number,
  depositAmount: number,
  // WEB-3702
  depositAmountDynamoney: number,
  depositAmountSupplier: number,
  amountFinanced: number,
  residual: number,
  rego: string | null,
  autoGenerateInspection: boolean | null,
}
