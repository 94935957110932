import { Observable } from "rxjs";
import { Application, GetApplicationOwnerFn } from "./application-service.domain";
import { PpsrAsset } from "./ppsr-service.domain";
import { PayloadApiResponse } from "./types.domain";
import { VerimotoLenderType } from "./environment.domain";

export type CreateAssetInspectionFn = (assetId: number, applicationId: number, data: VerimotoAssetInspectionData) => Observable<PayloadApiResponse<VerimotoCreateInspectionResponse>>;
export type GetAssetInspectionsForApplicationFn = (applicationId: number) => Observable<VerimotoInspectionTableDataWithInspectionDetails[]>;
export type DownloadVerimotoReportFn = (inspectionId: number) => Observable<any>;

export interface VerimotoInspectionResponse {
  id: number;
  applicationStatusId: number;
  applicationStatus: string;
  referenceNumber: string;
  externalRef: string;
  creationDate: string;
  totalDocuments: number;
  documentsPending: number;
  documentsUploaded: number;
  documentsAccepted: number;
  documentsRejected: number;
  documentsProcessed: number;
  uploadPercentage: number;
  completionPercentage: number;
  isSellerActionRequired: boolean;
  isBypassRequested: boolean;
  isPurged: boolean;
  isCancelled: boolean;
  company: {
    companyName: string
  };
  seller: {
    name: string;
    surName: string;
    email: string;
    phoneNumber: string
  };
  buyer: {
    name: string;
    surName: string;
    email: string;
    phoneNumber: string
  };
  primaryBrokerOrLender: {
    name: string;
    surName: string;
    email: string;
    mobile: string
  };
  sharedBrokersOrLenders: [
    {
      name: string;
      surName: string;
      email: string;
      mobile: string
    }
  ]
}

export interface VerimotoAssetSelection {
  name: string;
  guid: string;
}

export interface VerimotoStateSelection {
  stateCode: string;
  guid: string;
}

export interface VerimotoInspectionTypeSelection {
  assetName: string;
  inspectionType: string;
  guid: string;
}

export interface NewAssetInspectionDialogData {
  getApplicationOwnerFn: GetApplicationOwnerFn;
  createAssetInspectionFn: CreateAssetInspectionFn;
  application: Application;
  asset: PpsrAsset;
  verimotoLender: VerimotoLenderType;
  verimotoAssetInspectionTypes: VerimotoInspectionTypeSelection[];
}

export interface NewAssetInspectionDialogResult {
 newInspection: boolean;
}

export type VerimotoAssetInspectionBuyer = {
  name: string | null;
  surName: string | null;
  email: string | null;
  phoneNumberWithCountryCode: string | null;
} | null

export interface VerimotoAssetInspectionData {
  seller: {
    name: string;
    surName: string;
    email: string;
    phoneNumberWithCountryCode: string;
  };
  buyer: VerimotoAssetInspectionBuyer,
  lenderGuid: string;
  lenderRef: string;
  externalRef: string;
  assetGuid: string;
  planGuid: string | null;
  stateCode: string;
  brokersOrLenders: {
    email: string;
    isOwner: boolean;
  }[],
  moreInformation?: {
    isAcceptPayoutLetterForBankStatement: boolean;
    isAssetHasRego: boolean;
    isRegoTransferredToBuyer: boolean;
  }
}

export interface VerimotoCreateInspectionResponse {
  message: string;
  id: number
}

export interface VerimotoWebhookStatusUpdateData {
  ApplicationId: number;
  NewApplicationStatusId: number;
  NewApplicationStatus: string;
  ReferenceNumber: string;
  ExternalRef: string;
  SellerName: string;
  SellerSurName: string;
  SellerEmail: string;
  SellerPhoneNumber: string;
  NewPSIStatusId: number;
  NewPSIStatus: string;
  ServiceName: string;
  EventType: string;
  EmailStatus: boolean;
}

export interface VerimotoInspectionTableData {
  Id?: number;
  VerimotoInspectionId: number;
  AssetId: number;
  UserId: number;
  ApplicationId: number;
  CreatedTime: Date;
  LastUpdatedTime: Date;
  Status: string;
  SellerName: string;
  SellerSurName: string;
  SellerEmail: string;
  SellerPhoneNumber: string;
  BuyerName: string | null;
  BuyerSurName: string | null;
  BuyerEmail: string | null;
  BuyerPhoneNumber: string | null;
  LenderRef: string;
  ExternalRef: string;
  AssetGuid: string;
  StateCode: string;
  InspectionTypeGuid: string | null;
  BrokersOrLenders: string;
}

export interface NewVerimotoInspectionBody {
  data: VerimotoAssetInspectionData;
  assetId: number;
  applicationId: number;
}

export interface VerimotoInspectionTableDataWithInspectionDetails extends VerimotoInspectionTableData {
  inspectionDetails: VerimotoInspectionResponse
}

export interface VerimotoCallbackEvent {
  ApplicationId: number,
  NewApplicationStatusId: number,
  NewApplicationStatus: string,
  ReferenceNumber: string,
  ExternalRef: string,
  SellerName: string,
  SellerSurName: string,
  SellerEmail: string,
  SellerPhoneNumber: string,
  NewPSIStatusId: number,
  NewPSIStatus: string,
  ServiceName: string,
  EventType: string,
  EmailStatus: boolean,
}