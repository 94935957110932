import {Moment} from 'moment';

export interface AmortizationChartData {
  estimatedDrawdownDate: Moment,
  annualData: {
    year: string,
    balance: number,
    principal: number,
    interest: number,
  }[],
  quarterlyData: {
    quarter: string,
    balance: number,
    principal: number,
    interest: number,
  }[]
}
