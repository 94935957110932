import {Moment} from 'moment';
import { EmployerValue } from './employer-component.domain';
import { EmploymentStatusSelectionValue } from './employment-status-selection-component.domain';
import { GenderValue } from './gender-component.domain';
import { IncomeSelectionValue } from './income-component.domain';
import { MaritialStatusSelectionValue } from './maritial-status-selection-component.domain';
import { PropertyOwnerWithAddressValue } from './property-owner-with-address-component.domain';
import { ReferenceValue } from './reference-component.domain';
import { TimeInAddressValue } from './time-in-address-component.domain';
import { TitleSelectionValue } from './title-selection-component.domain';
import { UserSelectionValue } from './user-selection-component.domain';
import { YesNoValue } from './yes-no-component.domain';
import {RepaymentFrequencyType} from '../../app-calculator';
import { SalesforceContactSameAccountSelectionValue } from './salesforce-contact-same-account-selection-component.domain';

export interface ConsumerFinanceSummary {
  repayment?: string;
  estimatedDrawDownDate?: Moment;
  applicationId?: string;
  repaymentFrequency: RepaymentFrequencyType;
  title: TitleSelectionValue,
  firstName: string,
  lastName: string,
  dob: Moment,
  gender: GenderValue,
  privacyConsent: YesNoValue,
  timeInAddress: TimeInAddressValue,
  propertyOwnerWithAddress: PropertyOwnerWithAddressValue,
  email: string,
  mobileNumber: string,
  maritalStatus: MaritialStatusSelectionValue,
  numberOfDependants: string,
  employmentStatus: EmploymentStatusSelectionValue,
  regularIncome: IncomeSelectionValue,
  employer: EmployerValue,
  addtionalBrokerCorrespondent?: UserSelectionValue,
  brokerContact:SalesforceContactSameAccountSelectionValue,
  spouse?: {
    title: TitleSelectionValue,
    firstName: string,
    lastName: string,
    dob: Moment,
    gender: GenderValue,
    email: string,
    mobileNumber: string,
    employmentStatus: EmploymentStatusSelectionValue,
    regularIncome: IncomeSelectionValue,
    employer: string,
    employerContact: string,
    guarantor: YesNoValue,
    privacyConsent: YesNoValue,
  },

  expanses: {
    homeLoans: string,
    electricity: string,
    carLoans: string,
    otherUtilities: string,
    personalLoans: string,
    education: string,
    totalCreditCardLimits: string,
    groceries: string,
    otherLoans: string,
    insurance: string,
    shareMonthlyLivingExpanses: string,
  }

  references: ReferenceValue,

  applicationNotes: string;

}

