/// <reference types="@types/googlemaps" />
// import PlaceResult = google.maps.places.PlaceResult;

export interface GeocoderAddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface PlaceAspectRating {
    rating: number;
    type: string;
}

export interface PlaceGeometry {
    location: LatLng;
    viewport: LatLngBounds;
}

export interface LatLng {
    lat(): number;
    lng(): number;
}

export interface LatLngBounds {
    getCenter(): LatLng;
    getNorthEast(): LatLng;
    getSouthWest(): LatLng;
    isEmpty(): boolean;
}

export interface OpeningHours {
    open_now: boolean;
    periods: OpeningPeriod[];
    weekday_text: string[];
    isOpen(date?: Date): boolean;
}

export interface OpeningPeriod {
    open: OpeningHoursTime;
    close?: OpeningHoursTime;
}

export interface OpeningHoursTime {
    day: number;
    hours: number;
    minutes: number;
    nextDate: number;
    time: string;
}


export interface PlacePhoto {
    height: number;
    html_attributions: string[];
    width: number;
    getUrl(opts: PhotoOptions): string;
}


export interface PhotoOptions {
    maxHeight?: number;
    maxWidth?: number;
}


export interface PlacePlusCode {
    compound_code?: string;
    global_code: string;
}


export interface PlaceReview {
    aspects: PlaceAspectRating[];
    author_name: string;
    author_url?: string;
    language: string;
    profile_photo_url: string;
    rating: number;
    relative_time_description: string;
    text: string;
    time: number;
}

export interface PlaceResult {
    address_components?: GeocoderAddressComponent[];
    adr_address?: string;
    aspects?: PlaceAspectRating[];
    formatted_address?: string;
    formatted_phone_number?: string;
    geometry?: PlaceGeometry;
    html_attributions?: string[];
    icon?: string;
    id?: string;
    international_phone_number?: string;
    name: string;
    opening_hours?: OpeningHours;
    permanently_closed?: boolean;
    photos?: PlacePhoto[];
    place_id?: string;
    plus_code?: PlacePlusCode;
    price_level?: number;
    rating?: number;
    reviews?: PlaceReview[];
    types?: string[];
    url?: string;
    user_ratings_total?: number;
    utc_offset?: number;
    utc_offset_minutes?: number;
    vicinity?: string;
    website?: string;
}

export type AddressComponentValue = {
  address: string | null;
  place?: PlaceResult;
  location?: any;
} | null;

