import { BusinessSearchValue } from "./business-search-component.domain";
import { DirectorValue } from "./director-component.domain";
import { EntityTypeValue } from "./entity-type-component.domain";
import { AddressComponentValue } from "./google-address-component.domain";
import { GuarantorValue } from "./guarantor-component.domain";
import { MemberValue } from "./member-component.domain";
import { PartnerValue } from "./partner-component.domain";
import { PrimaryIndustrySelectionValue } from "./primary-industry-selection-component.domain";
import { ReferenceValue } from "./reference-component.domain";
import { SecondaryIndustrySelectionValue } from "./secondary-industry-selection-component.domain";
import { ContactValue } from "./select-contact-component.domain";
import { SoleTraderValue } from "./sole-trader-component.domain";
import { TrusteeValue } from "./trustee-component.domain";
import { UserSelectionValue } from "./user-selection-component.domain";
import { YesNoValue } from "./yes-no-component.domain";
import { ApplicationApplicant } from '../types.domain';
import {Moment} from "moment/moment";
import {AggregatorSearchComponentValue} from "./aggregator-search-component.domain";
import {FacilityEstablishmentValue} from "./facility-establishment-component.domain";
import {LoanTermValue} from "./loan-terms-selection-component.domain";
import {PaymentFrequencyValue} from "./payment-frequency-selection-component.domain";
import {Address2ComponentValue} from "./address2-component.domain";
import { SalesforceContactSameAccountSelectionValue } from "./salesforce-contact-same-account-selection-component.domain";

export interface CorporateLoanSummary {

  monthlyRepayment: string;
  estimatedDrawDownDate: Moment;
  applicationId: string;

  organisation: BusinessSearchValue;
  addtionalBrokerCorrespondent: UserSelectionValue;
  broker: AggregatorSearchComponentValue;

  facilityEstablishmentFee: number|undefined;
  facilityEstablishmentFeePercent:FacilityEstablishmentValue;
  loanPurpose: string;
  loanAmount: number;
  loanTerms: LoanTermValue;
  paymentFrequency: PaymentFrequencyValue;
  propertyOwner: YesNoValue;
  adverseOnFile: YesNoValue;
  equifaxScoreAboveThreshold: YesNoValue;

  organisationType: EntityTypeValue;
  abn: string;
  acn: string;
  annualRevenue: number;
  operatesInCommercialPremises: YesNoValue;
  primaryIndustry: PrimaryIndustrySelectionValue;
  industrySector: SecondaryIndustrySelectionValue;
  primaryBusinessAddress: Address2ComponentValue;
  businessLandline: string;

  applicants: Exclude<ApplicationApplicant, null>;

  guarantors: GuarantorValue;

  primaryContact: ContactValue;
  brokerflowDocumentId: string;
  references: ReferenceValue;

  applicationNotes: string;
  directorScore: YesNoValue;

  whatDoesTheBusinessSell: string;
  whatDoesTheBusinessSellTo: string;
  brokerContact:SalesforceContactSameAccountSelectionValue;

  // applicationId: string;

  // organisation: BusinessSearchValue;
  // addtionalBrokerCorrespondent: UserSelectionValue;

  // organisationType: EntityTypeValue;
  // abn: string;
  // acn: string;
  // annualRevenue: number;
  // limitRequest: number;
  // operateOnCommercialPremises: YesNoValue;
  // primaryIndustry: PrimaryIndustrySelectionValue;
  // industrySector: SecondaryIndustrySelectionValue;
  // primaryBusinessAddress: AddressComponentValue;
  // businessLandline: string;

  // applicants: Exclude<ApplicationApplicant, null>,

  // guarantors: GuarantorValue;

  // whatDoesTheBusinessSell: string;
  // whatDoesTheBusinessSellTo: string;

  // primaryContact: ContactValue;

  // references: ReferenceValue;

  // applicationNotes: string;
}

