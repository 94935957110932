import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiResponse, PayloadApiResponse } from '../types.domain';
import { DscrIndustry, DscrIndustryEbitda } from '../../const';
import { BankstatementAndBasiqDataStatus, BasiqReferenceType } from '../basiq.domain';
import { BankStatementData, BankStatementTransactionRow, DayEndBalanceSummary, Revenue } from '../bank.domain';

export interface BankStatementsAnalysisData {
  AccountSummary: AccountSummary[];
  Dishonours: DebitCreditSummary;
  LenderList: DebitCreditSummary;
  Overdrawn: DebitCreditSummary;
  Revenue: Revenue;
  Summary: BankStatementsAnalysisCategory[];
  SummaryByBankId: BankStatementAnalysisCategoryByBankId;
  debitcredit: DebitCreditSummary;
  Error?: string;
}

export type GetBankStatementsAnalysisFn = (params: {
  reference: string // abn
}) => Observable<PayloadApiResponse<BankStatementData | null>>

export type GetBankStatementAndBasiqDataStatusFn = (reference: string, type: BasiqReferenceType) => Observable<PayloadApiResponse<BankstatementAndBasiqDataStatus | null>>

export interface PeriodSummary {
  totalamt: number;
  totalcnt: number;
  avgmonthly: number;
  avgcnt: number;
  Numberofdays: number;
  Numberofmonths: number;
}

export interface LenderListSummary {
  Numberofmonths: number;
  avgcntcredit: number;
  avgcntdebit: number;
  avgmonthlycredit: number;
  avgmonthlydebit: number;
  endperiod: string;
  startperiod: string;
  totalcntcredit: number;
  totalcntdebit: number;
  totalcredit: number;
  totaldebit: number;
}

export interface AccountSummary {
  BankID: number;
  AccHolder: string;
  AccName: string;
  AmtCredit: number;
  AmtDebit: number;
  AvgBalance: number;
  AvgBalance90: number;
  AvgBalance180: number;
  BSBAccNumber: string;
  BankName: string;
  ClosingBalance: number;
  DaysinNegative: number;
  DayswithTransactions: number;
  Dishonours: PeriodSummary;
  Dishonours90: PeriodSummary;
  Dishonours180: PeriodSummary;
  EXITAmtCredit: number;
  EXITAmtCredit90: number;
  EXITAmtCredit180: number;
  MedianAmtCredit: number;
  MedianAmtCredit90: number;
  MedianAmtCredit180: number;
  EXITAmtDebit: number;
  EXITAmtDebit90: number;
  EXITAmtDebit180: number;
  EXITNumCredit: number;
  EXITNumCredit90: number;
  EXITNumCredit180: number;
  EXITNumDebit: number;
  EXITNumDebit90: number;
  EXITNumDebit180: number;
  FirstDate: string;
  LastDate: string;
  LenderList: LenderListSummary;
  LoanCreditFlag: boolean;
  MinBalance: number;
  'Non SACC Loans': PeriodSummary;
  'Non SACC Loans90': PeriodSummary;
  'Non SACC Loans180': PeriodSummary;
  'Non SACC LoansCredit': PeriodSummary;
  'Non SACC Loans90Credit': PeriodSummary;
  'Non SACC Loans180Credit': PeriodSummary;
  'Non SACC LoansDebit': PeriodSummary;
  'Non SACC Loans90Debit': PeriodSummary;
  'Non SACC Loans180Debit': PeriodSummary;
  NumCredit: number;
  NumDebit: number;
  OpeningBalance: number;
  Overdrawn: PeriodSummary;
  Overdrawn90: PeriodSummary;
  Overdrawn180: PeriodSummary;
  availableBalance: number;
  currentBalance: number;
  dayEndBalances: DayEndBalanceSummary[];
}

export interface DebitCreditSummary {
  averagecredit: number;
  averagedebit: number;
  details: BankStatementsAnalysisTransactionDetails[];
  enddate: string;
  enddatecredit: string;
  enddatedebit: string;
  monthlycredit: number[];
  monthlydebit: number[];
  numcredit: number;
  numdebit: number;
  startdate: string;
  startdatecredit: string;
  startdatedebit: string;
  totalcredit: number;
  totaldebit: number;
}

export interface BankStatementsAnalysisCategory {
  amountcredit: number;
  amountdebit: number;
  avgcredit: number;
  avgdebit: number;
  category: string;
  details: BankStatementsAnalysisTransactionDetails[];
  numcredit: number;
  numdebit: number;
}

export interface BankStatementAnalysisCategoryByBankId {
  [keys: string]: BankStatementsAnalysisCategory[];
}

export interface TransactionTag {
  [keys: string]: string;
}

export interface AccountFilter {
  [keys: string]: FormControl<boolean | null>;
}

export interface TransactionFilter {
  [keys: string]: FormControl<boolean | null>;
}

export interface BankStatementsAnalysisTransactionDetails {
  BankID: number;
  amount: number;
  date: string;
  tags: TransactionTag[];
  text: string;
  type: string;
  description: string;
  id: number;
}

export interface CategoryDetailsTableData {
  date: string;
  text: string;
  party: string;
  credit: number;
  debit: number;
  id: number;
}

export interface CategoryTransactionCheckbox {
  [key: number]: { [id: string]: boolean }; // key - category index
}

export interface BankStatementsAnalysisTransactionData {
  date: string;
  transaction: string;
  credit: number;
  debit: number;
  party: string | undefined;
  category: string | undefined;
  otherInfo: string | undefined;
  bankId: number;
  id?: number;
}

export type BankStatementDecisioningSelection = 'overview' | 'loan payment' | 'income' | 'ato payment' | 'dscr calculator';

export interface BsaLender {
  id: number;
  lender: string;
  lastUpdatedTime: string;
  updatedBy: number;
  isDeleted: boolean;
}

export interface UpdateLenderListBody {
  lenders: string[];
}

export interface UpdateLenderBody {
  id: number;
  lender: string;
}

export interface AddLenderBody {
  lender: string;
}

export type GetBsaLenderListFn = () => Observable<BsaLender[]>;
export type GetBsaExcludedLenderListFn = () => Observable<BsaLender[]>;

export interface BsaCalculator {
  id?: number;
  applicationId: number;
  lastUpdatedTime: string;
  updatedBy?: number;
  isDeleted?: boolean;
  industry: DscrIndustry;
  industryEbitda: DscrIndustryEbitda;
  adjustmentPercent: number;
  marginAdoptedPercent: number;
  monthlySalesAverage3Months: number;
  monthlySalesAverage6Months: number;
  monthlySalesAverage12Months: number;
  averageMonthlySalesAdopted: number;
  salesAdjustment: number;
  averageMonthlySalesAdoptedAfterAdjustment: number;
  cashAvailableForDebtServiceMonthly: number;
  existingDebtCommitmentsMonthly: number;
  atoPaymentPlanMonthly: number;
  additionalCommitments: number;
  proposedCommitmentsMonthly: number;
  totalFinancingCommitmentsMonthly: number;
  debtServiceCoverRatio: number;
  selectedAccounts: string[];
  loanPaymentTransactions: BankStatementTransactionRow[];
  incomeTransactions: BankStatementTransactionRow[];
  atoPaymentTransactions: BankStatementTransactionRow[];
  notes: string;
}

export interface DscrCalculatorHistory {
  id?: number;
  applicationId: number;
  lastUpdatedTime: string;
  updatedBy?: number;
  isDeleted?: boolean;
  industry: DscrIndustry;
  industryEbitda: DscrIndustryEbitda;
  adjustmentPercent: number;
  marginAdoptedPrecent: number;
  monthlySalesAverage3Months: number;
  monthlySalesAverage6Months: number;
  monthlySalesAverage12Months: number;
  averageMonthlySalesAdopted: number;
  salesAdjustment: number;
  averageMonthlySalesAdoptedAfterAdjustment: number;
  cashAvailableForDebtServiceMonthly: number;
  existingDebtCommitmentsMonthly: number;
  atoPaymentPlanMonthly: number;
  additionalCommitments: number;
  proposedCommitmentsMonthly: number;
  totalFinancingCommitmentsMonthly: number;
  debtServiceCoverRatio: number;
  GivenName?: string;
  FamilyName?: string;
}

export interface SaveBsaCalculatorBody {
  applicationId: number,
  data: Partial<BsaCalculator>,
  saveDscrCalculator: boolean,
}

export interface EditLenderDialogData {
  type: 'Edit' | 'Add';
  title: 'Lender' | 'Excluded Lender';
  lender: string;
}

export interface EditLenderDialogResult {
  readyForSubmission: boolean;
  lender: string;
}

export type GetBsaCalculatorFn = (applciationId: number) => Observable<BsaCalculator | null>;
export type SaveBsaCalculatorFn = (body: SaveBsaCalculatorBody) => Observable<ApiResponse>;
export type UpdateBsaLenderFn = (body: UpdateLenderBody) => Observable<ApiResponse>;
export type UpdateBsaExcludedLenderFn = (body: UpdateLenderBody) => Observable<ApiResponse>;
export type DeleteBsaLenderFn = (id: number) => Observable<ApiResponse>;
export type DeleteBsaExcludedLenderFn = (id: number) => Observable<ApiResponse>;
export type AddBsaLenderFn = (body: AddLenderBody) => Observable<ApiResponse>;
export type AddBsaExcludedLenderFn = (body: AddLenderBody) => Observable<ApiResponse>;
export type GetDscrCalculatorHistoryFn = (applciationId: number) => Observable<DscrCalculatorHistory[]>;

export interface OverdraftQuarterlyReview {
  pismoAccountNumber: number,
  opportunityId: string,
  abn: string,
  revenue0: number,
  revenue1: number,
  revenue2: number,
  revenue3: number,
  previousAvgRevenues: number,
  newLoanPaymentParties: string[],
  dishonourTransactions: BankStatementsAnalysisTransactionDetails[]
}