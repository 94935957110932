import {Observable} from 'rxjs';
import {ApiResponse, PayloadApiResponse} from '../types.domain';
import {Application, ApplicationTypes, GetApplicationOwnerFn, GetOriginatorBusinessByIdFn, RatecardDetails, UpdateApplicationFn} from '../application-service.domain';
import {
  ContractDetails,
  ContractEmailBody, ContractStatusResponse, DeclineContractBody,
  DeleteContractBody,
  GenerateContractBody,
  GenerateContractResponse,
  SendContractResponse, SyncContractDetailsBody, UpdateApplicationStageBody
} from '../docusign-service.domain';
import {GetApplicationindividualsPayload} from '../digital-id-service.domain';
import {PpsrAsset} from '../ppsr-service.domain';
import { GetAccountDetailsFromSfFn } from '../pismo.domain';
import { CreateApplicationAuditLog } from '../application-audit-service.domain';

export type DigitalIdGetApplicationIndividualsFn = (applicationId: number) => Observable<GetApplicationindividualsPayload>;
export type AssetSettlementGetApplicationAssetFn = (applicationId: number) => Observable<PpsrAsset[]>;
export type GetRateCardDetailsFn = (userId: number, type: ApplicationTypes) => Observable<RatecardDetails>;

export type SendContractEmailsFn = (body: ContractEmailBody) => Observable<SendContractResponse>;
export type GetContractStatusFn = (applicationId: number) => Observable<ContractStatusResponse>;
export type GenerateContractFn = (body: GenerateContractBody) => Observable<GenerateContractResponse>;
export type DeleteContractFileFn = (body: DeleteContractBody) => Observable<ApiResponse>;
export type GetActiveContractForApplicationFn = (applicationId: number) => Observable<ContractDetails | null>;
export type DeclineContractFn = (body: DeclineContractBody) => Observable<ApiResponse>;
export type SyncContractDetailsToSfFn = (body: SyncContractDetailsBody) => Observable<ApiResponse>;
export type UpdateApplicationStageInSfFn = (body: UpdateApplicationStageBody) => Observable<ApiResponse>;
export type GetApplicationByIdFn = (applicationId: number) => Observable<Application>;
export type AddAuditLogFn = (data: CreateApplicationAuditLog) => Observable<CreateApplicationAuditLog>;
export interface GenerateContractDialogData {
  application: Application,
  sendContractEmailsFn: SendContractEmailsFn;
  generateContractFn: GenerateContractFn;
  getRatecardDetailsFn: GetRateCardDetailsFn;
  getApplicationIndividualsFn: DigitalIdGetApplicationIndividualsFn;
  getApplicationAssetFn?: AssetSettlementGetApplicationAssetFn;
  deleteContractFileFn: DeleteContractFileFn;
  syncContractDetailsToSfFn: SyncContractDetailsToSfFn;
  getApplicationOwnerFn: GetApplicationOwnerFn;
  getAccountDetailsFromSfFn: GetAccountDetailsFromSfFn;
  updateApplicationFn: UpdateApplicationFn;
  getOriginatorBusinessByIdFn: GetOriginatorBusinessByIdFn;
  apiUrl: string;
}

export interface GenerateContractDialogResult {

}

export interface ContractStatusDialogData {
  envelopeId: string;
  applicationId: number;
  getContractStatusFn: GetContractStatusFn;
  declineContractFn: DeclineContractFn;
}

export interface ContractStatusDialogResult {

}
