import {TermRateForCorporateLoan} from "../../app-calculator";

export interface CorporateLoanPaymentBreakupDialogData {
  term: TermRateForCorporateLoan
}

export interface CorporateLoanPaymentBreakupDialogData2 {
  loadFn: () => CorporateLoanPaymentBreakupDialogData;
  isQuote: boolean;
  hideBrokerage: boolean;
}

export interface CorporateLoanPaymentBreakupDialogResult { acceptedQuotation: boolean };
