import {MinMaxRateValue} from './min-max-rate-component.domain';
import {SecurityTypeRateValue} from "./security-type-rate-component.domain";

export type CorporateLoanRateCardValue = CorporateLoanRateCard | null;
export interface CorporateLoanRateCard {
  propertyOwner: number,
  noPropertyOwner: number,
  facilityFee: number,
  brokerShareFacilityFee:number,
  docFee: MinMaxRateValue,
  directorScoreRate: number,
  nonPropertyOwnerBetween500And600Rate: number,
  rbaRate: number,
  securityType: SecurityTypeRateValue,
}
