import {TitleSelectionValue} from '../component/title-selection-component.domain';

export type ContactValue = {
  isManual: boolean | null,
  title: TitleSelectionValue | null,
  firstName: string,
  lastName: string,
  mobileNumber: string,
  telephone: string,
  email: string,
  areaCode: string,
};
export type SelectContactValue = ContactValue | null;

