import {
  CoverTypeSelectionValue
} from '../component/cover-type-selection-component.domain';
import {
  InsurerSelectionValue
} from '../component/insurer-selection-component.domain';
import {Moment} from 'moment';

export type Policy = {
  coverType: CoverTypeSelectionValue,
  insurer: InsurerSelectionValue,
  policyNumber: string,
  inceptionDate: Moment,
  expiryDate: Moment,
  premiumAmount: number,
  invoiceNumber: string,
}

export type PolicyValue = {
  total: number;
  paidByMonthlyInstallment: '10' | '12';
  monthlyInstallment: number;
  applicationFee: number;
  brokerage: number;
  policies: Policy[];
} | null;
