import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'inverse',
    pure: true,
    standalone: true
})
export class InversePipe implements  PipeTransform {

  transform(value: any, ...args: any[]): any {
    const num = parseFloat(value);
    if (num == 0) {
      return num;
    }
    if (!isNaN(num)) {
      const result = 1 / num;
      if (!isNaN(result)) {
        const r = Number(result.toFixed(8));
        return r;
      }
    }
    return value;
  }

}
